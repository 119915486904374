import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

import { Paragraph } from '../Paragraph/Paragraph';

export const ViewOnMap = styled(Paragraph)`
  font-size: 14px;
  margin-bottom: 30px;
`;

export const Address = styled(Paragraph)`
  font-size: 14px;
  margin: 0 0 30px;
  line-height: 1.25rem;
`;

export const IconLink = styled(Link)`
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  color: ${colors.black};
  gap: 6px;
`;
export const VisitWebsite = styled(IconLink)`
  margin-left: auto;
`;

export const Left = styled.div<{ $border?: boolean }>`
  font-size: 14px;
  display: inline-flex;
  padding-right: 20px;

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    padding-inline-end: ${spacing.small};
    block-size: 100%;
    align-items: center;
  }
  ${({ $border }) =>
    $border &&
    css`
      border-right: thin solid ${colors.gray4};
    `}
`;

export const Middle = styled.div`
  font-size: 14px;
  display: inline-flex;
  padding: 0 20px;

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    padding: 0 ${spacing.small};
    border-inline-end: thin solid ${colors.gray4};
    border-inline-start: thin solid ${colors.gray4};
    block-size: 100%;
    align-items: center;
  }
`;

export const Right = styled.div`
  font-size: 14px;
  display: inline-flex;
  padding-left: 20px;

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    padding-inline-start: ${spacing.small};
    block-size: 100%;
    align-items: center;
  }
`;

export const Contact = styled.div`
  display: flex;
  border-top: thin solid ${colors.gray4};
  flex-direction: column;
  height: auto;
  padding: 20px 0;
  align-items: flex-start;
  margin-block-end: -8px;

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    flex-direction: row;
    block-size: 60px;
    padding: 0;
    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.bigMobile}) {
    justify-content: center;
  }
`;

export const StoreListStyled = styled.div`
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-end: 0;
  }
`;
