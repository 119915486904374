import Markdown from 'markdown-to-jsx';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import { useGlobal } from '@hultafors/hultafors/hooks';
import { ArticleFactBoxFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H2 } from '../H2/H2';
import { Section } from '../Section/Section';

import { FactBoxStyled } from './FactBox.styled';
const AspectRatioImage = dynamic(() =>
  import('@hultafors/shared/components').then(
    (module) => module.AspectRatioImage,
  ),
);

interface FactBoxProps extends ArticleFactBoxFragment {
  indexKey: string;
}

export const FactBox: React.FC<FactBoxProps> = ({
  indexKey,
  age,
  name,
  profession,
  livesIn,
  image,
  instagram,
  websiteUrl,
  websiteText,
  title,
  extraField,
}) => {
  const { globalContent } = useGlobal();
  return (
    <Section>
      <FactBoxStyled>
        <Grid
          key={indexKey}
          columns={[
            { columns: 4 },
            { breakpoint: 'largeMobile', columns: 6 },
            { breakpoint: 'mobileMax', columns: 12 },
          ]}
        >
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'largeMobile', columns: 2 },
              { breakpoint: 'mobileMax', columns: 3, start: 3 },
              { breakpoint: 'desktopMedium', columns: 2, start: 4 },
            ]}
          >
            {image?.responsiveImage && (
              <AspectRatioImage desktopImage={image} />
            )}
          </GridChild>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'largeMobile', columns: 4, start: 3 },
              { breakpoint: 'mobileMax', columns: 5, start: 6 },
              { breakpoint: 'desktopMedium', columns: 4, start: 6 },
            ]}
          >
            <div className="TextContent">
              <H2>{title}</H2>
              <ul className="FactsWrapper">
                {name && (
                  <li>
                    {globalContent?.nameLabel}:{name}
                  </li>
                )}

                {profession && (
                  <li>
                    {globalContent?.professionLabel}:{profession}
                  </li>
                )}

                {livesIn && (
                  <li>
                    {globalContent?.livesInLabel}:{livesIn}
                  </li>
                )}
                {age && (
                  <li>
                    {globalContent?.ageLabel}:{age}
                  </li>
                )}
                {instagram && (
                  <li>
                    {globalContent?.instagramLabel}:{' '}
                    <Link
                      href={`https://www.instagram.com/${instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @{instagram}
                    </Link>
                  </li>
                )}
                {websiteUrl && (
                  <li>
                    {globalContent?.websiteLabel}:{' '}
                    <Link
                      href={websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {websiteText}
                    </Link>
                  </li>
                )}
                {extraField && (
                  <li>
                    <Markdown>{extraField}</Markdown>
                  </li>
                )}
              </ul>
            </div>
          </GridChild>
        </Grid>
      </FactBoxStyled>
    </Section>
  );
};
