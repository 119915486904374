import { useRouter } from 'next/router';
import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { RELATED_PRODUCTS_PAGE_SIZE } from '@hultafors/hultafors/helpers/constants';
import { useGlobal } from '@hultafors/hultafors/hooks';
import { HultaforsProduct } from '@hultafors/hultafors/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { H2 } from '../H2/H2';
import { Products } from '../Products/Products';

import { RelatedProductsStyled } from './RelatedProducts.styled';

interface RelatedProductsProps {
  header?: string;
  linkUrl?: string;
  linkText?: string;
  categorySlug: string;
  productId: string;
  relationId: string;
}

export const RelatedProducts: React.FC<RelatedProductsProps> = ({
  header = '',
  linkUrl = '',
  linkText = '',
  categorySlug,
  productId,
  relationId,
}) => {
  const { globalContent } = useGlobal();
  const { locale } = useRouter();
  const { data, isLoading } = useSWR<
    ParsedProductsApiResponse<HultaforsProduct>
  >(
    relationId &&
      `/api/productrelationlist/${relationId}?${new URLSearchParams({
        pageSize: `${RELATED_PRODUCTS_PAGE_SIZE}`,
        productId: `${productId}`,
        ...(locale && locale !== 'com' ? { locale } : {}),
      }).toString()}`,
    {
      revalidateOnMount: true,
    },
  );
  if (!data || !data.items.length) {
    return null;
  }
  return (
    <RelatedProductsStyled>
      {header && (
        <Grid>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'desktop', columns: 12, start: 2 },
            ]}
          >
            <H2>{header}</H2>
          </GridChild>
        </Grid>
      )}

      <Grid
        columnGap={[{ columnGap: 20 }]}
        columns={[
          { columns: 1 },
          { breakpoint: 'mediumMobile', columns: 2 },
          { breakpoint: 'desktop', columns: 4 },
        ]}
      >
        <Products
          products={data?.items || []}
          badgeText={globalContent.newLabel || ''}
          fromText={globalContent.fromLabel || ''}
          premiumText={globalContent.premiumLabel || ''}
          modelText={globalContent.modelsLabel || ''}
          loading={isLoading}
          numberOfItems={RELATED_PRODUCTS_PAGE_SIZE}
          categorySlug={categorySlug}
        />
      </Grid>

      {linkUrl && linkText && (
        <div className="LinkWrapper">
          <ButtonLink className="Black ButtonLink" to={linkUrl}>
            {linkText}
          </ButtonLink>
        </div>
      )}
    </RelatedProductsStyled>
  );
};
