import { slugify } from '@hultafors/shared/helpers';

import { HultaforsProductDetails } from '@hultafors/hultafors/types';

const LANDING_VIEW = '/';
const PRODUCTS = 'products';
const ARTICLES = 'articles';
const INTERVIEWS = 'stories';
const NEWS = 'news';
const PAGE = 'page';
const FIND_RETAILERS = 'find-retailers';
const SEARCH = 'search';
const CONTACT = 'contact';
const NOT_FOUND = 'page-not-found';
const GUIDES = 'guides';
const CAMPAIGNS = 'campaigns';
const ICONS = 'icons';

export const routes = {
  ARTICLES,
  CAMPAIGNS,
  CONTACT,
  FIND_RETAILERS,
  GUIDES,
  ICONS,
  INTERVIEWS,
  LANDING_VIEW,
  NEWS,
  NOT_FOUND,
  PAGE,
  PRODUCTS,
  SEARCH,
};

export const createDetailPageUrl = (
  {
    name,
    productId,
    sku,
  }: {
    name: string;
    categorySlug?: string;
    productId: string;
    sku: string;
  },
  categorySlug: string,
) => {
  const productSlug = slugify(name);
  categorySlug = categorySlug ? categorySlug.trim() : '';

  // Hack for broken product
  if (!categorySlug && productSlug.includes('folding-rule')) {
    categorySlug = 'folding_rules';
  }

  return `/${routes.PRODUCTS}/${categorySlug}/${sku}-${productSlug}/${productId}`;
};

export const createCategoryUrl = (slug: string) => {
  return `/${routes.PRODUCTS}/${slug}`;
};
export const createIconUrl = (slug: string) => {
  return `/${routes.ICONS}/${slug}`;
};

export const createDetailPageUrlFromDetails = (
  { name, id, sku }: HultaforsProductDetails,
  categorySlug: string,
) => {
  const productSlug = slugify(name);
  categorySlug = categorySlug ? categorySlug.trim() : '';

  return `/${routes.PRODUCTS}/${categorySlug}/${sku}-${productSlug}/${id}`;
};
