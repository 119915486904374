import { Fragment, useEffect, useState } from 'react';

import Link from 'next/link';

import { LanguageData } from '@hultafors/shared/types';

import { Markdown } from '../markdown/markdown';

import {
  BottomSectionLinks,
  CookieSettings,
  DesktopMenu,
  DesktopMenuItem,
  DesktopMenuTitle,
  FooterEndContainer,
  FooterIntro,
  FooterStyled,
  Inner,
  LeftMid,
  LeftSection,
  LogoLink,
  MarketPickerToggler,
  MenuLink,
  MobileMenu,
  MobileMenuItem,
  Outer,
  RightSection,
  SocialIcon,
  SocialIconsWrapper,
  SocialMediaSection,
  TogglerTextContainer,
  Triangle,
} from './footer.styled';

interface BrandLink {
  title?: string | null | undefined;
  url?: string | null | undefined;
}

interface FooterLink {
  title?: string;
  url?: string;
}
interface FooterMenuItem {
  heading?: string;
  links?: FooterLink[];
  includeCookieToggle?: boolean;
}

interface SocialMediaMenuItem {
  __typename?: 'IconLinkRecord' | undefined;
  url?: string | null | undefined;
  icon?:
    | {
        __typename?: 'FileField' | undefined;
        alt?: string | null | undefined;
        tags: string[];
        title?: string | null | undefined;
        url: string;
        width?: any;
        height?: any;
        format: string;
      }
    | null
    | undefined;
}

interface FooterProps {
  items: FooterMenuItem[];
  logoIcon: React.ReactNode;
  brandLinks?: BrandLink[];
  socialMediaMenu?: SocialMediaMenuItem[];
  logoAriaLabel: string;
  introText?: string;
  moreInfoText?: string;
  selectCountryLabel?: string;
  toggleMarketPicker: () => void;
  activeMarket?: LanguageData | null;
  bottomIntro?: string;
  socialMediaLabel?: string | null | undefined;
  cookieSettingsLabel?: string | null | undefined;
  brand?: 'hellberg' | 'snickers' | 'solidgear' | 'hultafors' | 'wibe';
}

export const Footer = ({
  activeMarket,
  brand,
  items,
  logoAriaLabel,
  brandLinks,
  logoIcon,
  introText,
  socialMediaMenu,
  moreInfoText,
  selectCountryLabel,
  toggleMarketPicker,
  bottomIntro,
  socialMediaLabel,
  cookieSettingsLabel,
}: FooterProps) => {
  const [marketName, setMarketName] = useState<string>();

  const handleOnPressSettings = () => {
    // TODO fix types for window
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  const onClickCookieSettings: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();
    handleOnPressSettings();
  };

  function brandLinksMapper(link: BrandLink, index: number) {
    if (!link?.url || !link?.title) {
      return null;
    }
    return (
      <MenuLink href={link.url ?? ''} key={`Company-${index}`}>
        {link.title}
      </MenuLink>
    );
  }

  function menuMapper(item: FooterMenuItem, index: number) {
    if (!item?.links) {
      return null;
    }

    let hrefTarget = '_self';

    return (
      <Fragment key={`accordion-menu-${item.heading}-${index}`}>
        <MobileMenu
          allowZeroExpanded
          key={`accordion-menu-${item.heading}-${index}`}
        >
          <MobileMenuItem plus label={item.heading} $brand={brand}>
            {item.links?.map((link, subIndex: number) => {
              if (!link?.url) {
                return null;
              }

              if (link.url?.includes('https') || link.url?.includes('www')) {
                hrefTarget = '_blank';
              }
              return (
                <MenuLink
                  href={link.url}
                  target={hrefTarget}
                  key={`${link.title}-${subIndex + index}`}
                >
                  {link.title}
                </MenuLink>
              );
            })}
          </MobileMenuItem>
        </MobileMenu>
        <DesktopMenu>
          <DesktopMenuItem>
            <DesktopMenuTitle $brand={brand}>{item?.heading}</DesktopMenuTitle>
            {item.links?.map((link, subIndex: number) => {
              if (link.url?.includes('https') || link.url?.includes('www')) {
                hrefTarget = '_blank';
              }
              return (
                <MenuLink
                  href={link.url ?? ''}
                  target={hrefTarget}
                  key={`${link.title}-${subIndex + index}`}
                >
                  {link.title}
                </MenuLink>
              );
            })}
          </DesktopMenuItem>
          {item?.includeCookieToggle && (
            <CookieSettings
              aria-label={cookieSettingsLabel ?? 'Cookies'}
              type="button"
              role="button"
              onClick={onClickCookieSettings}
            >
              {cookieSettingsLabel}
            </CookieSettings>
          )}
        </DesktopMenu>
      </Fragment>
    );
  }

  function socialMediaMapper(item: SocialMediaMenuItem, index: number) {
    if (!item.url) {
      return null;
    }

    return (
      <Link
        key={`SocialMedia-${index}`}
        href={item.url ?? ''}
        aria-label={item.icon?.title ?? ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SocialIcon src={item?.icon?.url ?? ''} alt={item?.icon?.alt ?? ''} />
      </Link>
    );
  }

  useEffect(() => {
    if (!activeMarket?.name) {
      return;
    }
    setMarketName(activeMarket.name);
  }, [activeMarket?.name]);

  return (
    <FooterStyled>
      <Outer>
        {logoIcon && (
          <LogoLink href="/" aria-label={logoAriaLabel}>
            {logoIcon}
          </LogoLink>
        )}
        <Inner>
          <LeftSection>
            {bottomIntro && (
              <FooterIntro $brand={brand}>{bottomIntro}</FooterIntro>
            )}
            <LeftMid $brand={brand}>
              {introText && (
                <Markdown options={{ forceBlock: true }}>{introText}</Markdown>
              )}

              {brandLinks && (
                <BottomSectionLinks $brand={brand}>
                  {brandLinks.map(brandLinksMapper)}
                </BottomSectionLinks>
              )}
            </LeftMid>
            {moreInfoText && <Markdown>{moreInfoText}</Markdown>}
            {socialMediaMenu && (
              <SocialMediaSection $brand={brand}>
                <span>{socialMediaLabel}</span>
                <SocialIconsWrapper>
                  {socialMediaMenu.map(socialMediaMapper)}
                </SocialIconsWrapper>
              </SocialMediaSection>
            )}
          </LeftSection>
          <RightSection>
            {items && items.map(menuMapper)}
            {selectCountryLabel && marketName && (
              <FooterEndContainer>
                <DesktopMenuTitle $brand={brand}>
                  {selectCountryLabel}
                </DesktopMenuTitle>
                <MarketPickerToggler
                  aria-label={selectCountryLabel ?? 'Toggle Market'}
                  type="button"
                  role="button"
                  onClick={toggleMarketPicker}
                >
                  <TogglerTextContainer $brand={brand}>
                    <span>{marketName}</span>
                    <span>{marketName}</span>
                  </TogglerTextContainer>
                  <Triangle />
                </MarketPickerToggler>
              </FooterEndContainer>
            )}
          </RightSection>
        </Inner>
      </Outer>
    </FooterStyled>
  );
};
