import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

interface SectionStyledProps {
  $borderBottomDesktop?: boolean;
  $borderTop?: boolean;
  $fullBleed?: boolean;
  $gray?: boolean;
  $hide?: boolean;
  $largePaddingTop?: boolean;
  $moreMarginBottom?: boolean;
  $morePaddingBottom?: boolean;
  $noMargin?: boolean;
  $noMarginTop?: boolean;
  $paddingBottom?: boolean;
  $smallMarginTop?: boolean;
}

export const SectionStyled = styled.section<SectionStyledProps>`
  &:empty {
    display: none;
  }

  display: ${({ $hide }) => ($hide ? 'none' : 'block')};
  padding: 0 ${spacing.pagePaddingMobile};
  margin: ${spacing.medium} 0;
  ${({ $borderTop }) =>
    $borderTop &&
    css`
      border-top: thin solid ${colors.gray4};
      padding-top: ${spacing.medium};
    `}

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0 ${spacing.pagePadding};
    ${({ $borderTop }) =>
      $borderTop &&
      css`
        padding-top: ${spacing.xlarge};
      `}
    margin: ${spacing.xlarge} 0;
  }

  ${({ $smallMarginTop }) =>
    $smallMarginTop &&
    css`
      margin: ${spacing.block} 0 ${spacing.medium} 0;

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin: ${spacing.large} 0 ${spacing.xlarge} 0;
      }
    `}

  ${({ $fullBleed }) =>
    $fullBleed &&
    css`
      padding: 0;
    `}

  ${({ $borderBottomDesktop, $noMargin }) =>
    $borderBottomDesktop &&
    $noMargin &&
    css`
      @media screen and (min-width: ${breakpoints.desktop}) {
        border-block-end: thin solid ${colors.gray4};
      }
    `}

  ${({ $noMarginTop }) =>
    $noMarginTop &&
    css`
      margin: 0 0 ${spacing.medium} 0;

      @media screen and (min-width: ${breakpoints.desktop}) {
        margin: 0 0 ${spacing.xlarge} 0;
      }
    `}

  ${({ $noMargin }) =>
    $noMargin &&
    css`
      margin: 0;
    `}

  ${({ $gray }) =>
    $gray &&
    css`
      background-color: ${colors.gray4};
    `}

  ${({ $paddingBottom }) =>
    $paddingBottom &&
    css`
      padding-bottom: ${spacing.xlarge};
    `}

  ${({ $paddingBottom, $morePaddingBottom }) =>
    $paddingBottom &&
    $morePaddingBottom &&
    css`
      padding-bottom: ${spacing.xxlarge};
    `}
`;
