import { useEffect, useState } from 'react';

import { isMorePages } from '@hultafors/shared/helpers';

import { getParentPage } from '@hultafors/hultafors/helpers/utils';
import { useGlobal } from '@hultafors/hultafors/hooks';
import {
  ExploreAreaFragment,
  ExploreListType,
} from '@hultafors/hultafors/types';

import { H2 } from '../H2/H2';
import { Pager } from '../Pager/Pager';

import {
  ArticleItem,
  ArticlePlugCustomStyle,
  ExploreAreaBlockStyledNew,
  ExploreAreaColumnsStyled,
  ExploreAreaHorizontalStyled,
  ExploreContentWrapper,
  ExploreDescription,
  ExploreHeader,
} from './ExploreAreaBlockNew.styled';

export const ExploreAreaBlockTypename = 'ExploreAreaRecord';

type ExploreAreaBlockNewProps = ExploreAreaFragment;

export const ExploreAreaBlockNew: React.FC<ExploreAreaBlockNewProps> = ({
  plugList,
  description,
  title,
  horizontal = false,
}) => {
  const { globalContent } = useGlobal();
  const [plugItems, setPlugItems] = useState<ExploreListType[]>([]);
  const [paging, setPaging] = useState({
    itemCount: plugList.length,
    pageCount: Math.ceil(plugList.length / 6),
    pageNumber: 1,
    pageSize: 6,
  });

  const col1: ExploreListType[] = [],
    col2: ExploreListType[] = [];

  if (plugItems && !horizontal) {
    plugItems.forEach((item, i) => {
      if (i % 2 === 0) {
        col2.push(item);
      } else {
        col1.push(item);
      }
    });
  }

  useEffect(() => {
    if (plugList) {
      const items = plugList.slice(0, 6);
      setPlugItems(items);
    }
  }, [plugList]);

  const showMorePlugs = () => {
    const page = paging.pageNumber + 1;
    const items = plugList.slice(0, 6 * page);
    setPlugItems(items);
    setPaging({ ...paging, pageNumber: page });
  };
  function getColumnMapper(items: ExploreListType[], prefix: string) {
    return items.map((item: ExploreListType, i: number) => {
      return (
        <ArticleItem key={`Article-${prefix}-${i}`}>
          <ArticlePlugCustomStyle
            title={item.title || ''}
            desktopImage={item.desktopImage || undefined}
            mobileImage={item.mobileImage || undefined}
            url={`/${getParentPage(item.__typename || '')}/${item.slug || ''}`}
            label={item.label || ''}
            darkText={item.darkText}
          />
        </ArticleItem>
      );
    });
  }

  function plugItemsMapper(item: ExploreListType, i: number) {
    return (
      <ArticleItem key={`Article-plug-${i}`}>
        <ArticlePlugCustomStyle
          title={item.title || ''}
          desktopImage={item.desktopImage || undefined}
          mobileImage={item.mobileImage || undefined}
          url={`/${getParentPage(item.__typename || '')}/${item.slug}`}
          label={item.label || ''}
          darkText={item.darkText}
        />
      </ArticleItem>
    );
  }
  if (!plugItems?.length) {
    return null;
  }
  return (
    <ExploreAreaBlockStyledNew>
      <ExploreHeader>
        <H2>{title}</H2>
        {description && (
          <ExploreDescription variant="dark" options={{ forceBlock: true }}>
            {description}
          </ExploreDescription>
        )}
      </ExploreHeader>
      <ExploreContentWrapper>
        {col1 && col2 && !horizontal ? (
          <ExploreAreaColumnsStyled>
            <div className="col1">{getColumnMapper(col1, 'col1')}</div>
            <div className="col2">{getColumnMapper(col2, 'col2')}</div>
          </ExploreAreaColumnsStyled>
        ) : (
          <ExploreAreaHorizontalStyled>
            <div className="HorizontalWrapper">
              {plugItems.map(plugItemsMapper)}
            </div>
          </ExploreAreaHorizontalStyled>
        )}
        {plugList.length > 6 && (
          <Pager
            buttonLabel={globalContent?.showMoreLabel || ''}
            ofLabel={globalContent?.ofLabel || ''}
            onClick={showMorePlugs}
            paging={paging}
            morePages={isMorePages(paging)}
          />
        )}
      </ExploreContentWrapper>
    </ExploreAreaBlockStyledNew>
  );
};
