import { Select } from '../Select/Select';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';

import { StyledRetailersAdditionalSelections } from './RetailersAdditionalSelections.styled';

interface RetailersAdditionalSelectionsProps {
  onChangeRadius: React.ChangeEventHandler<HTMLSelectElement>;
  onChangeInternational: React.ChangeEventHandler<HTMLInputElement>;
  expandable: boolean;
  international: boolean;
  radius: number;
  radiusOptions: string[];
  radiusLabel: string;
  internationalLabel: string;
  showInternational?: boolean;
  loading?: boolean;
}

export const RetailersAdditionalSelections: React.FC<
  RetailersAdditionalSelectionsProps
> = ({
  onChangeRadius,
  onChangeInternational,
  expandable,
  international,
  radius,
  radiusLabel,
  radiusOptions,
  internationalLabel,
  showInternational,
  loading,
}) =>
  expandable || showInternational ? (
    <StyledRetailersAdditionalSelections>
      <div className="AdditionalSelections">
        <div className="RadiusSelectContainer">
          <Select
            id="radiusSelect"
            selected={`${radius} km`}
            label={radiusLabel}
            onChange={onChangeRadius}
            options={radiusOptions}
            disabled={loading}
          />
        </div>

        {showInternational && (
          <ToggleSwitch
            label={internationalLabel}
            checked={international}
            onChange={onChangeInternational}
            disabled={loading}
          />
        )}
      </div>
    </StyledRetailersAdditionalSelections>
  ) : null;
