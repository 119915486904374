export const spacing = {
  // 48px
  block: '2rem',

  // 96px
  large: '4rem',

  // 64px
  medium: '3rem',

  mega: '17.5rem',

  pagePadding: '30px',

  pagePaddingMobile: '20px',

  // 32px
  regular: '1.5rem',

  // 24px
  small: '1rem',

  // 152px
  xlarge: '6rem',

  xsmall: '0.5rem',
  // 280px
  xxlarge: '9.5rem',
  xxsmall: '0.25rem',
};

export type Spacing = keyof typeof spacing;
