import { createContext } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { ApiSettings } from '@hultafors/shared/types';

import { CartItemData } from '@hultafors/hultafors/types';

export interface CartContextValue {
  cart: CartItemData[];
  addCartItem(item: CartItemData): void;
  removeCartItem(sku: string): void;
  clearCart(): void;
  changeCartAmount(direction: 'up' | 'down', sku: string): void;
  setCartAmount(sku: string, amount: number): void;
}

export const CartContext = createContext<CartContextValue | null>(null);

interface CartProviderProps {
  children?: React.ReactNode;
  settings: ApiSettings;
}

export const CartProvider: React.FC<CartProviderProps> = ({
  children,
  settings,
}) => {
  const cart = useLocalStorageValue<CartItemData[]>(
    `cart-${settings?.market ?? 'com'}`,
    {
      defaultValue: [],
      initializeWithValue: false,
    },
  );

  const addCartItem = (itemToAdd: CartItemData) => {
    if (cart.value?.find((item) => item.sku === itemToAdd.sku)) {
      cart.set(
        cart.value?.map((item) => ({
          ...item,
          amount: item.sku === itemToAdd.sku ? item.amount + 1 : item.amount,
        })) || [],
      );
    } else {
      cart.set([...(cart.value || []), itemToAdd]);
    }
  };

  const removeCartItem = (sku: string) => {
    cart.set(cart.value?.filter((item) => item.sku !== sku) || []);
  };

  const clearCart = () => {
    cart.set([]);
  };

  const setCartAmount = (sku: string, amount: number) => {
    cart.set(
      cart.value?.map((item) => ({
        ...item,
        amount: item.sku === sku ? amount : item.amount,
      })) || [],
    );
  };

  const changeCartAmount = (direction: 'up' | 'down', sku: string) => {
    cart.set(
      cart.value?.map((item) => ({
        ...item,
        amount:
          item.sku === sku
            ? direction === 'up'
              ? item.amount + 1
              : item.amount - 1
            : item.amount,
      })) || [],
    );
  };

  return (
    <CartContext.Provider
      value={{
        addCartItem,
        cart: cart.value || [],
        changeCartAmount,
        clearCart,
        removeCartItem,
        setCartAmount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
