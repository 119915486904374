import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

interface VideoBlockStyledProps {
  $fullWidth?: boolean;
}

export const VideoBlockStyled = styled.div<VideoBlockStyledProps>`
  position: relative;
  margin: ${spacing.medium} 0;
  padding: 0 ${({ $fullWidth }) => !$fullWidth && spacing.pagePaddingMobile};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: ${spacing.xlarge} 0;
    padding: 0 ${({ $fullWidth }) => !$fullWidth && spacing.pagePadding};
  }
`;

export const Video = styled.video<VideoBlockStyledProps>`
  max-width: 100%;
  max-height: calc(100vh - var(--header-height));
  object-fit: contain;
  position: relative;

  @media screen and (min-width: ${breakpoints.desktop}) {
    max-inline-size: ${({ $fullWidth }) => ($fullWidth ? '100vw' : '100%')};
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  margin: 0;
  text-align: center;
`;

export const VideoControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  pointer-events: none;
`;

export const PlayButton = styled.button<{ $visible?: boolean }>`
  appearance: none;
  color: ${colors.white};
  background: none;
  border: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  ${({ $visible }) =>
    $visible &&
    css`
      opacity: 1;
      pointer-events: initial;
      transition: opacity 0.3s ease;
      cursor: pointer;
    `}
`;
