import { Market } from '@hultafors/shared/types';

// Country centroids sourced from:
// https://github.com/mihai-craita/countries_center_box

export const markets: Market[] = [
  {
    azure: { companyCode: '' },
    defaultCoords: {
      latitude: 57.6983323,
      longitude: 12.7388379,
    },
    hostname: 'www.hultafors.com',
    languages: [{ datoLocale: 'en', lang: 'en' }],
    market: 'com',
    pt: { market: 'com', siteId: '028' },
  },
  {
    azure: { companyCode: '30' },
    defaultCoords: {
      latitude: 50.5010789,
      longitude: 4.4764595,
    },
    hostname: 'www.hultafors.be',
    languages: [
      {
        datoFallbackLocale: 'nl_NL',
        datoLocale: 'nl_BE',
        lang: 'nl-BE',
        name: 'Nederlands',
        ptLang: '',
        urlPrefix: '',
      },
      {
        datoFallbackLocale: 'fr',
        // field used to call parttrap API
        datoLocale: 'fr_BE',

        lang: 'fr-BE',

        name: 'Français',

        // field used for path in url e.g sitename.be/fr/contact
        ptLang: 'fr',

        urlPrefix: 'fr',
      },
    ],
    market: 'be',
    pt: { market: 'be', siteId: '044' },
  },
  {
    azure: { companyCode: '80' },
    defaultCoords: {
      latitude: 46.818188,
      longitude: 8.227512,
    },
    hostname: 'www.hultafors.ch',
    languages: [
      {
        datoFallbackLocale: 'de',
        datoLocale: 'de_CH',
        lang: 'de-CH',
        name: 'Deutsch',
        ptLang: '',
        urlPrefix: '',
      },
      {
        datoFallbackLocale: 'fr',
        datoLocale: 'fr_CH',
        lang: 'fr-CH',
        name: 'Français',
        ptLang: 'fr',
        urlPrefix: 'fr',
      },
    ],
    market: 'ch',
    pt: { market: 'ch', siteId: '051' },
  },
  {
    azure: { companyCode: '70' },
    defaultCoords: {
      latitude: 51.165691,
      longitude: 10.451526,
    },
    hostname: 'www.hultafors.de',
    languages: [{ datoLocale: 'de', lang: 'de-DE' }],
    market: 'de',
    pt: { market: 'de', siteId: '046' },
  },
  {
    azure: { companyCode: '25' },
    defaultCoords: {
      latitude: 56.1554671,
      longitude: 10.4309955,
    },
    hostname: 'www.hultafors.dk',
    languages: [{ datoLocale: 'da_DK', lang: 'da-DK' }],
    market: 'dk',
    pt: { market: 'dk', siteId: '045' },
  },
  {
    azure: { companyCode: '15' },
    defaultCoords: {
      latitude: 61.92411,
      longitude: 25.748151,
    },
    hostname: 'www.hultafors.fi',
    languages: [{ datoLocale: 'fi', lang: 'fi-FI' }],
    market: 'fi',
    pt: { market: 'fi', siteId: '052' },
  },
  {
    azure: { companyCode: '45' },
    defaultCoords: {
      latitude: 46.227638,
      longitude: 2.213749,
    },
    hostname: 'www.hultafors.fr',
    languages: [{ datoLocale: 'fr', lang: 'fr-FR' }],
    market: 'fr',
    pt: { market: 'fr', siteId: '047' },
  },
  {
    azure: { companyCode: '10' },
    defaultCoords: {
      latitude: 53.41291,
      longitude: -8.24389,
    },
    hostname: 'www.hultafors.ie',
    languages: [
      { datoFallbackLocale: 'en', datoLocale: 'en_IE', lang: 'en-IE' },
    ],
    market: 'ie',
    pt: { market: 'ie', siteId: '048' },
  },
  {
    azure: { companyCode: '95' },
    defaultCoords: {
      latitude: 41.87194,
      longitude: 12.56738,
    },
    hostname: 'www.hultafors.it',
    languages: [
      {
        datoLocale: 'it',
        lang: 'it-IT',
        name: 'Italiano',
        ptLang: '',
        urlPrefix: '',
      },
      {
        // datoLocale: 'de_IT',
        // Todo: Switch back to de_IT when ready from dato
        datoLocale: 'de',

        lang: 'de-IT',

        name: 'Deutsch',

        ptLang: 'de',

        urlPrefix: 'de',
      },
    ],
    market: 'it',
    pt: { market: 'it', siteId: '049' },
  },
  {
    azure: { companyCode: '85' },
    defaultCoords: {
      latitude: 52.132633,
      longitude: 5.291266,
    },
    hostname: 'www.hultafors.nl',
    languages: [{ datoLocale: 'nl_NL', lang: 'nl-NL' }],
    market: 'nl',
    pt: { market: 'nl', siteId: '050' },
  },
  {
    azure: { companyCode: '35' },
    defaultCoords: {
      latitude: 60.472024,
      longitude: 8.468946,
    },
    hostname: 'www.hultafors.no',
    languages: [{ datoLocale: 'nb_NO', lang: 'no-NB' }],
    market: 'no',
    pt: { market: 'no', siteId: '064' },
  },

  {
    azure: { companyCode: '05' },
    defaultCoords: {
      latitude: 60.128161,
      longitude: 18.643501,
    },
    hostname: 'www.hultafors.se',
    languages: [{ datoLocale: 'sv', lang: 'sv-SE' }],
    market: 'se',
    pt: { market: 'se', siteId: '053' },
  },
  {
    azure: { companyCode: '55' },
    defaultCoords: {
      latitude: 55.378051,
      longitude: -3.435973,
    },
    hostname: 'www.hultafors.co.uk',
    languages: [
      {
        datoFallbackLocale: 'en',
        datoLocale: 'en_GB',
        lang: 'en-GB',
      },
    ],
    market: 'uk',
    pt: { market: 'uk', siteId: '054' },
  },
  {
    defaultCoords: {
      latitude: 39.50024,
      longitude: -98.350891,
    },
    hostname: 'www.hultafors.us',
    languages: [
      {
        datoLocale: 'en_US',
        lang: 'en-US',
      },
    ],
    market: 'us',
    pt: { market: 'us', siteId: '043' },
  },
];
