import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import {
  StyledArrowLink,
  StyledHeader,
  StyledParagraph,
} from './TwoColumnText.styled';
interface TwoColumnTextLeftHeaderProps {
  text?: string;
  title?: string;
  CTAText?: string;
  CTALink?: string;
}
export const TwoColumnTextLeftHeader: React.FC<TwoColumnTextLeftHeaderProps> = (
  props,
) => {
  return (
    <Grid columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 12 }]}>
      <GridChild
        columnSpan={[{ breakpoint: 'mobileMax', columns: 5, start: 2 }]}
      >
        {!!props.title && (
          <StyledHeader className="First">{props.title}</StyledHeader>
        )}
      </GridChild>

      <GridChild
        columnSpan={[{ breakpoint: 'mobileMax', columns: 5, start: 7 }]}
      >
        {!!props.text && <StyledParagraph>{props.text}</StyledParagraph>}
        {!!props.CTAText && !!props.CTALink && (
          <StyledArrowLink
            label={props.CTAText}
            url={props.CTALink}
            direction="forward"
          />
        )}
      </GridChild>
    </Grid>
  );
};
