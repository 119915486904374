import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

interface LanguageSelectorStyledProps {
  $dark?: boolean;
  $isDesktopMenu?: boolean;
}

export const OuterWrapper = styled.div`
  margin: 0 -${spacing.regular};
  border-bottom: thin solid ${colors.gray4};

  @media screen and (min-width: ${breakpoints.desktop}) {
    block-size: ${spacing.regular};
  }
`;

export const InnerWrapper = styled.div`
  max-width: ${breakpoints.maxPageWidth};
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  @media screen and (min-width: ${breakpoints.desktop}) {
    flex-direction: row;

    /* padding: 5px 20px; */
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    /* padding: 5px 20px; */
  }

  .DropdownLabel {
    padding: 0 ${spacing.regular};
  }
`;

export const InfoText = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 16px;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-block-end: 0;
    align-self: auto;
  }

  p {
    margin: 0;
    text-align: start;
    font-size: 14px;
    max-inline-size: 650px;

    @media screen and (min-width: ${breakpoints.desktop}) {
      max-inline-size: 800px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  align-self: flex-end;
  height: 26px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-inline: ${spacing.regular};
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    padding-inline: 0;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 16px;
  right: 20px;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    position: relative;
    padding: 0 6px;
    inset-block-start: 0;
    inset-inline-end: 0;
  }

  img {
    inline-size: 16px;
    block-size: 16px;
  }
`;

export const LanguageSelectorStyled = styled.div<LanguageSelectorStyledProps>`
  background: ${({ $dark }) => ($dark ? 'none' : colors.white)};

  ${({ $isDesktopMenu }) =>
    $isDesktopMenu &&
    css`
      @media screen and (max-width: ${breakpoints.desktop}) {
        display: none;
      }
    `}
`;
