import { useEffect, useState } from 'react';

import { useGlobal } from '@hultafors/hultafors/hooks';
import {
  ExploreAreaNewsBlockFragment,
  ExploreListType,
} from '@hultafors/hultafors/types';

import { ButtonLink } from '../ButtonLink/ButtonLink';
import { H2 } from '../H2/H2';
import { Pager } from '../Pager/Pager';

import {
  ArticleItem,
  ArticlePlugCustomStyle,
  ExploreAreaBlockStyled,
  ExploreAreaColumnsStyled,
  ExploreAreaHorizontalStyled,
  ExploreAreaMobileView,
  ExploreContentWrapper,
  ExploreDescription,
  ExploreHeader,
  LinkWrapper,
} from './ExploreAreaBlock.styled';

interface ExploreAreaBlockProps
  extends Omit<ExploreAreaNewsBlockFragment, '__typename'> {
  list: ExploreListType[];
  parentSlug: 'articles' | 'news' | 'stories';
  // description?: string;
  // title?: string;
  horizontal?: boolean;
  index: string;
  // displayPager?: boolean;
  // ctaButtonText?: string;
  // ctaPageUrl?: string;
}

export const ExploreAreaBlock: React.FC<ExploreAreaBlockProps> = ({
  list,
  parentSlug,
  description,
  title,
  horizontal = false,
  index,
  ctaButtonText,
  ctaPageUrl,
}) => {
  const { globalContent } = useGlobal();
  const [allItems] = useState<ExploreListType[]>(list);
  const [plugItems, setPlugItems] = useState<ExploreListType[]>([]);
  const [columns, setColumns] = useState<{
    col1: ExploreListType[];
    col2: ExploreListType[];
  }>({ col1: [], col2: [] });

  const [paging, setPaging] = useState({
    itemCount: allItems.length,
    pageCount: Math.ceil(allItems.length / 6),
    pageNumber: 1,
    pageSize: 6,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (plugItems && !horizontal && window.innerWidth > 600) {
        const col1: ExploreListType[] = [];
        const col2: ExploreListType[] = [];

        plugItems.forEach((item, i) => {
          if (i % 2 === 0) {
            col2.push(item);
          } else {
            col1.push(item);
          }
        });

        setColumns({ col1: col1, col2: col2 });
      }
    }
  }, [plugItems]);

  useEffect(() => {
    if (allItems) {
      const items = allItems.slice(0, 6);
      setPlugItems(items);
    }
  }, [allItems]);

  const showMorePlugs = () => {
    const page = paging.pageNumber + 1;
    const items = allItems.slice(0, 6 * page);
    setPlugItems(items);
    setPaging({ ...paging, pageNumber: page });
  };
  if (!plugItems?.length) {
    return null;
  }

  return (
    <ExploreAreaBlockStyled key={index}>
      <ExploreHeader>
        <H2>{title}</H2>
        {description && (
          <ExploreDescription variant="dark" options={{ forceBlock: true }}>
            {description}
          </ExploreDescription>
        )}
      </ExploreHeader>
      <ExploreContentWrapper>
        {columns && !horizontal && window.innerWidth > 600 ? (
          <ExploreAreaColumnsStyled>
            <div className="col1">
              {columns.col1.map((item, i) => {
                let desktopImage = item?.desktopImage;
                let mobileImage = item?.mobileImage;
                let title = item?.title;
                let label = item?.label;
                if (item?.__typename === 'NewsPageRecord') {
                  desktopImage = item.meatImageDesktop;
                  mobileImage = item.metaImageMobile;
                  title = item.metaTitle;
                  label = item?.publicationDate;
                }
                return (
                  <ArticleItem key={`Article-col1-${i}`}>
                    <ArticlePlugCustomStyle
                      title={title || ''}
                      desktopImage={desktopImage || undefined}
                      mobileImage={mobileImage || undefined}
                      url={`/${parentSlug}/${item.slug}`}
                      label={label || ''}
                      darkText={item.darkText}
                    />
                  </ArticleItem>
                );
              })}
            </div>
            <div className="col2">
              {columns.col2.map((item, i) => {
                let desktopImage = item?.desktopImage;
                let mobileImage = item?.mobileImage;
                let title = item?.title;
                let label = item?.label;
                if (item?.__typename === 'NewsPageRecord') {
                  desktopImage = item.meatImageDesktop;
                  mobileImage = item.metaImageMobile;
                  title = item.metaTitle;
                  label = item?.publicationDate;
                }
                return (
                  <ArticleItem key={`Article-col2-${i}`}>
                    <ArticlePlugCustomStyle
                      title={title || ''}
                      mobileImage={mobileImage || undefined}
                      desktopImage={desktopImage || undefined}
                      url={`/${parentSlug}/${item.slug}`}
                      label={label || ''}
                      darkText={item.darkText}
                    />
                  </ArticleItem>
                );
              })}
            </div>
          </ExploreAreaColumnsStyled>
        ) : !horizontal && window.innerWidth < 600 ? (
          <ExploreAreaMobileView>
            {plugItems.map((item, i) => {
              let desktopImage = item?.desktopImage;
              let mobileImage = item?.mobileImage;
              let title = item?.title;
              let label = item?.label;
              if (item?.__typename === 'NewsPageRecord') {
                desktopImage = item.meatImageDesktop;
                mobileImage = item.metaImageMobile;
                title = item.metaTitle;
                label = item?.publicationDate;
              }
              return (
                <ArticleItem key={`Article-${i}`}>
                  <ArticlePlugCustomStyle
                    title={title || ''}
                    mobileImage={mobileImage || undefined}
                    desktopImage={desktopImage || undefined}
                    url={`/${parentSlug}/${item.slug}`}
                    label={label || ''}
                    darkText={item.darkText}
                  />
                </ArticleItem>
              );
            })}
          </ExploreAreaMobileView>
        ) : (
          horizontal && (
            <ExploreAreaHorizontalStyled>
              <div className="HorizontalWrapper">
                {plugItems.map((item, i) => {
                  let desktopImage = item?.desktopImage;
                  let mobileImage = item?.mobileImage;
                  let title = item?.title;
                  let label = item?.label;
                  if (item?.__typename === 'NewsPageRecord') {
                    desktopImage = item.meatImageDesktop;
                    mobileImage = item.metaImageMobile;
                    title = item.metaTitle;
                    label = item?.publicationDate;
                  }
                  return (
                    <ArticleItem key={`Article-${i}`}>
                      <ArticlePlugCustomStyle
                        title={title || ''}
                        mobileImage={mobileImage || undefined}
                        desktopImage={desktopImage || undefined}
                        url={`/${parentSlug}/${item.slug}`}
                        label={label || ''}
                        darkText={item.darkText}
                      />
                    </ArticleItem>
                  );
                })}
              </div>
            </ExploreAreaHorizontalStyled>
          )
        )}

        {allItems.length > 6 && (
          <Pager
            buttonLabel={globalContent?.showMoreLabel || ''}
            ofLabel={globalContent?.ofLabel || ''}
            onClick={showMorePlugs}
            paging={paging}
            morePages={true}
          />
        )}
        {ctaPageUrl && ctaButtonText && (
          <LinkWrapper>
            <ButtonLink className="Black ButtonLink" to={ctaPageUrl}>
              {ctaButtonText}
            </ButtonLink>
          </LinkWrapper>
        )}
      </ExploreContentWrapper>
    </ExploreAreaBlockStyled>
  );
};
