import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { Paragraph } from '../Paragraph/Paragraph';

export const ProductIntroStyled = styled.div`
  position: relative;

  &.ShortIntro {
    padding-block-end: ${spacing.small};

    p {
      margin-block-end: 0;
    }
  }
`;

export const IntroText = styled(Paragraph)<{ readMoreOpen: boolean }>`
  overflow: hidden;
  max-block-size: 125px;
  transition: max-block-size 0.3s linear;

  ${({ readMoreOpen }) =>
    readMoreOpen &&
    css`
      max-block-size: 1000px;
    `}
`;

export const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ReadMoreButton = styled.button<{ open: boolean }>`
  color: ${colors.black};
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.regular};
  background: none;
  border: none;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.normal};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${colors.linkhover};

    svg path {
      fill: ${colors.linkhover};
    }
  }

  svg {
    display: inline-flex;
    inline-size: 16px;
    block-size: 16px;
    margin-inline-start: 8px;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }

  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(45deg);
      }
    `}
`;
