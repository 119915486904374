import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';

export const AutoCompleteSearchBoxStyled = styled.div`
  width: 100%;
  display: block;
  position: relative;

  &.MobileMenuSearch input {
    padding: 0;
  }
`;

export const Result = styled.div<{ $empty?: boolean; $show?: boolean }>`
  position: absolute;
  display: none;
  width: 100%;
  top: 80px;
  padding: ${spacing.regular} ${spacing.pagePaddingMobile} 0
    ${spacing.pagePaddingMobile};
  background-color: ${colors.white};
  border: thin solid ${colors.border};
  z-index: 4;
  text-align: start;

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    padding: ${spacing.medium} ${spacing.pagePaddingMobile} 0
      ${spacing.pagePaddingMobile};
  }

  ${({ $show }) =>
    $show &&
    css`
      display: block;
    `}

  ${({ $empty }) =>
    $empty &&
    css`
      padding-top: 0;
    `}
`;

export const ShowMoreButton = styled(Button)`
  margin-bottom: ${spacing.regular};

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    margin-block-start: ${spacing.xsmall};
  }
`;
