import { AnimatePresence, m } from 'framer-motion';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import { createCategoryUrl } from '@hultafors/hultafors/helpers/routing';
import { GlobalFields } from '@hultafors/hultafors/types';

import { AutoCompleteSearchBox } from '../AutoCompleteSearchBox/AutoCompleteSearchBox';
import { SlideInNew } from '../SlideInNew/SlideInNew';

import {
  AccordionItemContent,
  AccordionStyled,
  Divider,
  MobileMenuLink,
  MobileMenuStyled,
  MobileMenuSubLink,
} from './MobileMenu.styled';

interface MobileMenuProps {
  toggleMenu(value?: string): void;
  isOpen: boolean;
  headerText: string;
  menuItems: GlobalFields['allMenuItems'];
  searchPlaceholder: string;
  partnerPortalUrl?: string;
  badgeText: string;
  modelsText: string;
  showMoreText: string;
  noSearchResult: string;
  clearLabel: string;
  searchLabel: string;
  changeLanguageText: string;
  shopLinkUrl?: string;
  shopLinkLabel?: string;
  toggleCartMenu(value: string): void;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  menuItems,
  partnerPortalUrl = '/',
  toggleMenu,
  toggleCartMenu,

  ...props
}) => {
  return (
    <MobileMenuStyled>
      <SlideInNew
        toggle={toggleMenu}
        isOpen={props.isOpen}
        headerText={props.headerText}
        isMenu
        padContent
        className="mobileMenu"
        partnerPortalUrl={partnerPortalUrl}
        changeLanguageText={props.changeLanguageText}
        toggleCartMenu={toggleCartMenu}
      >
        <div className="SearchWrapper">
          <AutoCompleteSearchBox
            className="MobileMenuSearch"
            placeholder={props.searchPlaceholder}
            badgeText={props.badgeText}
            modelsText={props.modelsText}
            showMoreText={props.showMoreText}
            noSearchResult={props.noSearchResult}
            toggleMenu={toggleMenu}
            passive={true}
          />
        </div>
        <div className="MenuWrapper">
          <AccordionStyled allowZeroExpanded={true}>
            {menuItems?.map((item) =>
              item.children?.length ? (
                <AccordionItem key={`MobileMenuItem-${item.id}`}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.title}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <AccordionItemState>
                      {({ expanded }) => (
                        <AnimatePresence>
                          {expanded && (
                            <m.div
                              initial={{ height: 0 }}
                              animate={{ height: 'auto' }}
                              exit={{ height: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                              <AccordionItemContent>
                                {item.children?.map(
                                  ({ id, pageLink, title, hide }: any) => {
                                    if (pageLink?.slug && !hide) {
                                      return (
                                        <MobileMenuSubLink
                                          key={`subMenuItem-${id}`}
                                          href={
                                            createCategoryUrl(pageLink?.slug) ||
                                            ''
                                          }
                                          onClick={() => toggleMenu()}
                                        >
                                          {title}
                                        </MobileMenuSubLink>
                                      );
                                    }
                                    if (!hide) {
                                      return (
                                        <Divider key={`subMenuItem-${id}`} />
                                      );
                                    }
                                    return null;
                                  },
                                )}
                              </AccordionItemContent>
                            </m.div>
                          )}
                        </AnimatePresence>
                      )}
                    </AccordionItemState>
                  </AccordionItemPanel>
                </AccordionItem>
              ) : (
                <MobileMenuLink
                  href={item.url || ''}
                  onClick={() => toggleMenu()}
                  key={item.id}
                >
                  {item.title}
                </MobileMenuLink>
              ),
            )}
          </AccordionStyled>
        </div>
      </SlideInNew>
    </MobileMenuStyled>
  );
};
