import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  filterMarkets,
  getCanonicalUrl,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { datoApi, markets } from '@hultafors/hultafors/api';
import {
  GlobalFields,
  OutdoorPageFragment,
  PageProps,
} from '@hultafors/hultafors/types';

import { prodDomains } from '../next.domains';
const ContentArea = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.ContentArea),
);
const Page = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.Page),
);

export interface OutdoorPageProps extends PageProps {
  content: OutdoorPageFragment;
}

export const getStaticProps: GetStaticProps<OutdoorPageProps> = async ({
  locale,
}) => {
  const revalidate = 60; // 1 minute
  try {
    const dato = datoApi(locale);
    const {
      data: { outdoorPage: content, ...globalFields },
    } = await dato.getOutdoorPage();
    if (!content || content === null) {
      return {
        notFound: true,
      };
    }

    const canonical = getCanonicalUrl(
      locale,
      prodDomains,
      'www.hultafors.com',
      '/outdoor',
    );

    const hrefLanguages = getHrefLanguages(
      null,
      filterMarkets(markets, 'HULTAFORS'),
      content?.pageLocales,
    );

    return {
      props: {
        content,
        ...(globalFields as GlobalFields),
        canonical,
        hrefLanguages,
      },
      revalidate,
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
      revalidate,
    };
  }
};

const OutdoorPage: NextPage<OutdoorPageProps> = ({
  canonical,
  content,
  hrefLanguages,
}) => {
  return (
    <Page seo={content.seo} canonical={canonical} hrefLanguages={hrefLanguages}>
      <ContentArea content={content.contentArea} />
    </Page>
  );
};

export default OutdoorPage;
