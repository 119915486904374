import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';
import { FavoriteItem } from '@hultafors/shared/types';

import { createDetailPageUrl } from '@hultafors/hultafors/helpers/routing';
import { HultaforsProduct } from '@hultafors/hultafors/types';

import { FavoriteToggle } from '../FavoriteToggle/FavoriteToggle';

import {
  FavoriteContainer,
  ImageWrapper,
  ProductBadge,
  ProductBadgeWrapper,
  ProductImage,
  ProductInfo,
  ProductLink,
  ProductName,
  ProductStyled,
} from './Product.styled';

interface ProductProps {
  product: HultaforsProduct;
  premiumText?: string;
  badgeText: string;
  inFavorites?: boolean;
  isFavorite?: boolean;
  isSmall?: boolean;

  modelText?: string;
  modelsText?: string;
  categorySlug?: string;
}
export const Product: React.FC<ProductProps> = ({
  inFavorites = false,
  isSmall = false,
  badgeText,
  product,
  categorySlug,
}) => {
  const detailPageUrl = createDetailPageUrl(
    product,
    categorySlug || product.categorySlug || '',
  );

  const favoriteItem: FavoriteItem = {
    id: `${product.productId}`,
    image: product.image?.url || '',
    name: product.name,
    url: detailPageUrl,
  };

  const sizes = isSmall
    ? '200px'
    : [
        '(min-width: 1500px) 295px',
        `(min-width: ${breakpoints.desktopMedium}) 25vw`,
        `(min-width: ${breakpoints.desktop}) 33vw`,
        `(min-width: ${breakpoints.mediumMobile}) 50vw`,
        '100vw',
      ].join(', ');

  return (
    <ProductStyled $inFavorites={inFavorites} $isSmall={isSmall}>
      <FavoriteContainer $inFavorites={inFavorites}>
        <FavoriteToggle product={favoriteItem} />
      </FavoriteContainer>
      <ProductLink href={detailPageUrl || ''}>
        <ProductImage className={`${product.productId}`}>
          <ImageWrapper $inFavorites={inFavorites} $isSmall={isSmall}>
            <Image
              src={product.image?.url || '/assets/img/noimage.png'}
              alt={product.name || ''}
              fill
              style={{ objectFit: 'contain' }}
              sizes={sizes}
            />
          </ImageWrapper>
          <ProductBadgeWrapper>
            {product.isNew && <ProductBadge isNew>{badgeText}</ProductBadge>}
            {product.premiumLabel && (
              <ProductBadge premium>{product.premiumLabel}</ProductBadge>
            )}
            {product.sustainableLabel && (
              <ProductBadge styleName="BottomLeft" sustainable>
                {product.sustainableLabel}
              </ProductBadge>
            )}
          </ProductBadgeWrapper>
        </ProductImage>

        <ProductInfo>
          <ProductName>{product.name}</ProductName>
        </ProductInfo>
      </ProductLink>
    </ProductStyled>
  );
};
