import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

import { Button } from '../Button/Button';
interface ArticleFilterStyledProps {
  $isMobileFilter?: boolean;
  $hide?: boolean;
}

export const MobileFilterBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0 15px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

export const ButtonFilters = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 0 -${spacing.pagePaddingMobile};
  padding: ${spacing.small} ${spacing.pagePaddingMobile};

  @media screen and (min-width: ${breakpoints.desktop}) {
    overflow-x: hidden;
    margin: 0 0 ${spacing.small} 0;
    padding: 0;
  }

  button {
    margin: 0 ${spacing.small} 0 0;
  }

  label {
    padding: 20px;
    border: thin solid ${colors.border};
  }
`;

export const ArticleFilterStyled = styled.div<ArticleFilterStyledProps>`
  width: 100%;
  max-width: 1440px;
  margin-inline: auto;

  select {
    inline-size: 100px;
    margin-inline-end: 20px;
  }

  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `}
`;

export const Actions = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }

  button {
    block-size: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    img {
      margin-inline-start: ${spacing.xsmall};
    }
  }

  .IconButton {
    margin-inline-start: ${spacing.small};
  }
`;

export const ClearIcon = styled.span`
  margin-left: ${spacing.xsmall};
`;

export const FilterButton = styled.button`
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;

export const ClearContainer = styled.div`
  margin-block-end: ${spacing.xsmall};
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const IconButton = styled(Button)`
  padding: ${spacing.xsmall} ${spacing.small} 0 0;
  height: ${spacing.block};
  border: 0;

  svg {
    margin-inline-start: ${spacing.xsmall};
    inline-size: ${spacing.small};
    block-size: ${spacing.small};
  }
`;

interface SubFiltersProps {
  $active?: boolean;
  $numberOfOptions?: number;
}
export const SubFilters = styled.div<SubFiltersProps>`
  display: ${({ $active }) => ($active ? 'flex' : 'none')};
  flex-flow: column wrap;
  top: 36px;
  border: thin solid ${colors.gray4};
  padding: ${spacing.small};
  z-index: 2;
  background-color: ${colors.white};
  min-width: 220px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    position: absolute;
    max-block-size: 350px;

    ${({ $numberOfOptions }) =>
      typeof $numberOfOptions === 'number' &&
      $numberOfOptions > 9 &&
      css`
        min-width: 350px;
      `}

    ${({ $numberOfOptions }) =>
      typeof $numberOfOptions === 'number' &&
      $numberOfOptions > 16 &&
      css`
        min-width: 525px;
      `}
  }
`;

export const SubFilter = styled.div`
  cursor: pointer;

  &:not(:last-of-type) {
    margin-block-end: ${spacing.small};
  }

  span {
    font-size: ${fontSizes.small};
    letter-spacing: 0.04em;
  }

  input {
    visibility: hidden;
    inline-size: 0;
  }

  input:disabled,
  input:disabled + span {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const FilterWrapper = styled.div`
  position: relative;

  button {
    padding: 0;
    margin-inline-end: ${spacing.regular};
    color: ${colors.black};

    &.Active {
      svg {
        transform: rotate(180deg);
        inset-block-start: unset;
      }
    }

    &.ChildSelected {
      span {
        position: relative;
      }
    }
  }
`;

export const FilterLabel = styled.label<{ $checked?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: ${({ $checked }) =>
      $checked ? "url('/assets/gfx/check.svg')" : "''"};
    position: relative;
    inline-size: ${spacing.small};
    block-size: ${spacing.small};
    border: thin solid ${colors.black};
    display: block;
    margin-inline-end: ${spacing.xsmall};
  }

  &:hover {
    color: ${colors.gray2};

    &::before {
      background-color: ${colors.gray4};
    }
  }
`;

interface DropDownFiltersProps {
  $isMobileFilter?: boolean;
}

export const DropDownFilters = styled.div<DropDownFiltersProps>`
  display: flex;
  width: ${({ $isMobileFilter }) => ($isMobileFilter ? '100%' : 'inherit')};
  flex-direction: ${({ $isMobileFilter }) =>
    $isMobileFilter ? 'column' : 'row'};

  @media screen and (max-width: ${breakpoints.desktop}) {
    display: ${({ $isMobileFilter }) => (!$isMobileFilter ? 'none' : 'flex')};
  }

  ${({ $isMobileFilter }) =>
    $isMobileFilter &&
    css`
      > * {
        inline-size: 100%;

        button {
          inline-size: 100%;
          margin: 0;
        }
      }
    `}
`;

export const BoxFilters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-block-end: ${spacing.xsmall};
`;

export const Info = styled.div`
  margin-block-end: ${spacing.small};
`;
