import { Button } from '../Button/Button';

import {
  ErrorMessage,
  InputContainer,
  Label,
  StyledInput,
  StyledTextArea,
} from './Input.styled';

interface InputProps {
  errorMessage?: string;
  type?: string;
  label?: string;
  name?: string;
  id?: string;
  color?: string;
  background?: string;
  accept?: string;
  required?: boolean;
  capture?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  multiple?: boolean;
  checked?: boolean;
  textarea?: boolean;
  isLabelFirst?: boolean;
  placeholder?: string;
  pattern?: string;
  value?: string;
  defaultValue?: React.HTMLAttributes<HTMLInputElement>['defaultValue'];
  iconUrl?: string;
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  iconClick?: React.MouseEventHandler<HTMLButtonElement>;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const Input: React.FC<InputProps> = ({
  onChange,
  iconClick,
  onKeyDown,
  onKeyUp,
  id,
  type = 'text',
  name = 'Input',
  accept = '*',
  pattern = '[^#_@£$|*/]{1,500}',
  label = '',
  color = '',
  background = '',
  placeholder = '',
  value = '',
  defaultValue,
  iconUrl = '',
  isLabelFirst = true,
  required,
  textarea,
  multiple,
  checked,
  capture,
  errorMessage,
  inputMode,
}) => (
  <InputContainer $color={color} $background={background}>
    {label && isLabelFirst && (
      <Label $color={color} htmlFor={id}>
        {label}
      </Label>
    )}
    {textarea && (
      <StyledTextArea
        id={name}
        required={required}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
    )}
    {!textarea && (
      <>
        <StyledInput
          type={type}
          id={id}
          name={name}
          accept={accept}
          required={required}
          placeholder={placeholder}
          pattern={pattern}
          multiple={multiple}
          checked={checked}
          capture={capture}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          $color={color}
          $background={background}
          $iconUrl={iconUrl}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          inputMode={inputMode}
        />

        {iconUrl && (
          <>
            {iconClick ? (
              <Button className="IconButton" type="button" onClick={iconClick}>
                <img src={iconUrl} alt="input-button" />
              </Button>
            ) : (
              <img className="InputIcon" src={iconUrl} alt="input-icon" />
            )}
          </>
        )}
      </>
    )}
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    {label && !isLabelFirst && (
      <Label $color={color} htmlFor={id}>
        {label}
      </Label>
    )}
  </InputContainer>
);
