import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';

export const Label = styled.label<{ $color: CSSProperties['color'] }>`
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

export const InputContainer = styled.div<{
  $color?: CSSProperties['color'];
  $background?: CSSProperties['background'];
}>`
  width: 100%;
  box-sizing: border-box;
  font-size: ${fontSizes.body};
  position: relative;

  .IconButton {
    position: absolute;
    inset-inline-end: 0;
    padding: ${spacing.xsmall} ${spacing.small} 0 0;
    inline-size: ${spacing.block};
    block-size: ${spacing.block};

    img {
      inline-size: ${spacing.small};
      block-size: ${spacing.small};
    }
  }

  ${({ $color, $background }) => $color && $background && css``}
`;

const inputStyle = css`
  width: 100%;
  padding: 0.25rem ${spacing.small};
  border: 0;
  background-color: transparent;
  margin-top: ${spacing.xxsmall};
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.h4};
  font-size: ${fontSizes.small};
  box-sizing: border-box;
  appearance: none;

  &:focus {
    outline: 0;
  }
`;

export const StyledTextArea = styled.textarea`
  ${inputStyle};
  min-height: 150px;
  padding: ${spacing.small} ${spacing.medium};
  box-sizing: border-box;

  @media screen and (max-width: ${breakpoints.desktopMedium}) {
    font-size: ${fontSizes.body};
  }
`;

export const StyledInput = styled.input<{
  $color?: CSSProperties['color'];
  $background?: CSSProperties['background'];
  $iconUrl?: string;
}>`
  &[type='text'],
  &[type='email'] {
    ${inputStyle};
  }

  &[type='email'] {
    padding: ${spacing.xsmall} ${spacing.small};
    border: thin solid ${colors.white};
    background: ${colors.black};
    color: ${colors.white};

    ${({ $color }) =>
      $color &&
      css`
        border: thin solid ${$color};
        color: ${$color};
      `}

    ${({ $background }) =>
      $background &&
      css`
        background: ${$background};
      `}

    &:focus {
      background: ${colors.gray3};
      color: ${colors.black};
    }

    ${({ $iconUrl }) =>
      $iconUrl &&
      css`
        &::after {
          content: '';
          display: inline-flex;
          inline-size: 10px;
          block-size: 10px;
          color: red;
          background-image: url(${$iconUrl});
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          inset-inline-end: 0;
        }
      `}
  }

  @media screen and (max-width: ${breakpoints.desktopMedium}) {
    font-size: ${fontSizes.body};
  }
`;

export const ErrorMessage = styled.span`
  color: ${colors.redComplement};
  font-size: ${fontSizes.xsmall};
  padding-left: ${spacing.small};
  padding-top: ${spacing.xxsmall};
  position: absolute;
  display: block;
`;
