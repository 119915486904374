import { LazyMotion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { CartProvider } from '../cart-context/cart-context';
import { FavoritesProvider } from '../favorites-context/favorites-context';
import {
  GlobalProvider,
  GlobalProviderProps,
} from '../global-context/global-context';

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then(
    (module) => module.MarketPickerProvider,
  ),
);

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);

type AppProviderProps = GlobalProviderProps;

const swrConfig = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnMount: false,
  revalidateOnReconnect: false,
};

export const AppProvider: React.FC<AppProviderProps> = ({
  settings,
  global,
  footer,
  allMenuItems,
  featureToggle,
  children,
  siteLocale,
}) => {
  const { locale } = useRouter();

  let currentLocale;

  if (siteLocale) {
    currentLocale = `${siteLocale.parttrapLanguage}-${siteLocale.parttrapMarket}`;
  }

  function getActiveLocale(locale: string | undefined) {
    switch (locale) {
      case 'en':
        return 'www.hultafors.com';
      case 'nl-be':
        return 'www.hultafors.be';
      case 'de-ch':
        return 'www.hultafors.ch';
      case 'de-de':
        return 'www.hultafors.de';
      case 'da-dk':
        return 'www.hultafors.dk';
      case 'fi-fi':
        return 'www.hultafors.fi';
      case 'fr-fr':
        return 'www.hultafors.fr';
      case 'en-ie':
        return 'www.hultafors.ie';
      case 'it-it':
        return 'www.hultafors.it';
      case 'nl-nl':
        return 'www.hultafors.nl';
      case 'nb-no':
        return 'www.hultafors.no';
      case 'sv-se':
        return 'www.hultafors.se';
      case 'en-gb':
        return 'www.hultafors.co.uk';
      case 'en-us':
        return 'www.hultafors.us';
      default:
        return 'www.hultafors.com';
    }
  }

  const active = getActiveLocale(locale).replace('https://', '');

  return (
    <SWRProvider config={swrConfig}>
      <LazyMotion features={lazyMotionFeatures}>
        <CartProvider settings={settings}>
          <FavoritesProvider settings={settings}>
            <GlobalProvider
              global={global}
              footer={footer}
              allMenuItems={allMenuItems}
              settings={settings}
              featureToggle={featureToggle}
              siteLocale={siteLocale}
            >
              <MarketPickerProvider
                label={global?.changeLanguage ?? ''}
                markets={global?.languages || []}
                active={active}
                currentLocale={currentLocale}
              >
                {children}
              </MarketPickerProvider>
            </GlobalProvider>
          </FavoritesProvider>
        </CartProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
