import { useState } from 'react';

import { CartItemData } from '@hultafors/hultafors/types';

import { CartContext } from './cart-context';

export const MockCartProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [cart, setCart] = useState<CartItemData[]>([]);

  const addCartItem = (itemToAdd: CartItemData) => {
    if (cart?.find((item) => item.sku === itemToAdd.sku)) {
      setCart(
        cart?.map((item) => ({
          ...item,
          amount: item.sku === itemToAdd.sku ? item.amount + 1 : item.amount,
        })) || [],
      );
    } else {
      setCart([...(cart || []), itemToAdd]);
    }
  };

  const removeCartItem = (sku: string) => {
    setCart(cart?.filter((item) => item.sku !== sku) || []);
  };

  const clearCart = () => {
    setCart([]);
  };

  const setCartAmount = (sku: string, amount: number) => {
    setCart(
      cart?.map((item) => ({
        ...item,
        amount: item.sku === sku ? amount : item.amount,
      })) || [],
    );
  };

  const changeCartAmount = (direction: 'up' | 'down', sku: string) => {
    setCart(
      cart?.map((item) => ({
        ...item,
        amount:
          item.sku === sku
            ? direction === 'up'
              ? item.amount + 1
              : item.amount - 1
            : item.amount,
      })) || [],
    );
  };

  return (
    <CartContext.Provider
      value={{
        addCartItem,
        cart,
        changeCartAmount,
        clearCart,
        removeCartItem,
        setCartAmount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
