import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { Paragraph } from '../Paragraph/Paragraph';
import { TextButton } from '../TextButton/TextButton';

export const RetailerTabsStyled = styled.div`
  margin: ${spacing.medium} 0;
`;

export const DetailInfo = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    font-size: ${fontSizes.body};
  }
`;

export const TabsContainer = styled.div<{ $center?: boolean }>`
  display: flex;
  text-align: center;

  > * + * {
    margin-inline-start: ${spacing.regular};
  }

  ${({ $center }) =>
    $center &&
    css`
      justify-content: center;
    `}
`;

export const TabButton = styled(TextButton)<{ $active?: boolean }>`
  display: block;
  padding: 0;

  /* margin-bottom: ${({ $active }) => ($active ? '0px' : '1px')}; */
`;

export const TabLabel = styled(Paragraph)<{ $active?: boolean }>`
  margin: 0 0 ${spacing.xxsmall} 0;
  color: ${({ $active }) => ($active ? colors.red : colors.black)};
  text-transform: uppercase;
  font-size: ${fontSizes.xsmall};
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.2em;

  &::after {
    content: '';
    display: block;
    margin: 0 auto;
    inline-size: 25%;
    border-block-end: thin solid
      ${({ $active }) => ($active ? colors.red : 'transparent')};
  }
`;
