import { FC } from 'react';

import { createCategoryUrl } from '@hultafors/hultafors/helpers/routing';
import { MenuItem } from '@hultafors/hultafors/types';

import { AutoCompleteSearchBox } from '../AutoCompleteSearchBox/AutoCompleteSearchBox';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { SlideInNew } from '../SlideInNew/SlideInNew';

import {
  DesktopMenuContent,
  DesktopMenuContentWrapper,
  DesktopMenuLink,
  DesktopMenuList,
  DesktopMenuStyled,
  DesktopMenuWrapper,
  Divider,
} from './DesktopMenu.styled';

export interface DesktopMenuProps {
  isOpen: boolean;
  toggleDesktopMenu: (val: string) => void;
  type?: string;
  premiumText?: string;
  searchPlaceholder: string;
  badgeText: string;
  modelsText: string;
  showMoreText: string;
  noSearchResult: string;
  clearLabel: string;
  searchLabel: string;
  dropDownMenuItem?: MenuItem;
}

export const DesktopMenu: FC<DesktopMenuProps> = ({
  dropDownMenuItem,
  isOpen,
  toggleDesktopMenu,
  type,
  premiumText,
  searchPlaceholder,
  badgeText,
  modelsText,
  showMoreText,
  noSearchResult,
  clearLabel,
  searchLabel,
  ...props
}) => {
  return (
    <DesktopMenuStyled>
      <SlideInNew
        toggle={toggleDesktopMenu}
        isOpen={isOpen}
        fromTop
        small={type === 'search'}
        id="desktopMenu"
      >
        <DesktopMenuWrapper>
          <DesktopMenuContentWrapper $show={type === 'search'}>
            <Grid columns={12}>
              <GridChild columnSpan={[{ columns: 6, start: 4 }]}>
                <DesktopMenuContent>
                  <AutoCompleteSearchBox
                    className="SearchWrapper"
                    placeholder={searchPlaceholder}
                    badgeText={badgeText}
                    premiumText={premiumText}
                    modelsText={modelsText}
                    showMoreText={showMoreText}
                    isOpen={isOpen}
                    toggleMenu={toggleDesktopMenu}
                    noSearchResult={noSearchResult}
                    clearLabel={clearLabel}
                    searchLabel={searchLabel}
                  />
                </DesktopMenuContent>
              </GridChild>
            </Grid>
          </DesktopMenuContentWrapper>
          {!!dropDownMenuItem?.children?.length && (
            <DesktopMenuContentWrapper $show={type === dropDownMenuItem.title}>
              <Grid columns={12}>
                <GridChild columnSpan={[{ columns: 10, start: 2 }]}>
                  <DesktopMenuContent>
                    <DesktopMenuList>
                      {dropDownMenuItem.children.map((item) =>
                        item?.pageLink?.slug &&
                        item.title !== '-' &&
                        !item.hide ? (
                          <DesktopMenuLink
                            key={`Sub-Menu-${item.id}`}
                            href={createCategoryUrl(item.pageLink?.slug || '')}
                            onClick={() => type && toggleDesktopMenu(type)}
                          >
                            {item.title}
                          </DesktopMenuLink>
                        ) : (
                          !item?.hide && (
                            <Divider key={`Sub-Menu-${item?.id}`} />
                          )
                        ),
                      )}
                    </DesktopMenuList>
                  </DesktopMenuContent>
                </GridChild>
              </Grid>
            </DesktopMenuContentWrapper>
          )}
        </DesktopMenuWrapper>
      </SlideInNew>
    </DesktopMenuStyled>
  );
};
