export const colors = {
  black: 'rgba(0, 0, 0, 1)',
  blue: 'rgba(47, 128, 237, 1)',
  blueLight: 'rgba(86, 204, 242, 1)',
  blueMid: 'rgba(45, 156, 219, 1)',
  border: '#E5E5E5',
  boxshadowTransparent: 'rgba(0, 0, 0, 0.1)',
  darkGreen: '#78A856',
  gray1: '#5E5A59',
  gray2: '#949090',
  gray3: '#D3D3D3',
  gray4: '#E5E5E5',
  gray5: '#F7F7F7',
  grayBtnHover: '#E9E8E8',
  green: '#48E3A6',
  linkhover: '#5E5A59',
  orange: 'rgba(242, 153, 74, 1)',
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',
  red: '#c52f25',
  redComplement: '#fd1000',
  redHover: '#b62c23',
  textshadow: 'rgba(0, 0, 0, 0.8)',
  textshadowLight: 'rgba(0, 0, 0, 0.6)',
  toggleGray: '#DEDEDE',
  white: 'rgba(255, 255, 255, 1)',
  yellow: 'rgba(242, 201, 76, 1)',
};
