import { ListApiPaging } from '@hultafors/shared/types';

import { Button } from '../Button/Button';
import { Gauge } from '../Gauge/Gauge';

import { PagerStyled } from './Pager.styled';

interface PagerProps {
  buttonLabel: string;
  ofLabel: string;
  productsLabel?: string;
  paging?: ListApiPaging;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  morePages: boolean;
  loading?: boolean;
  exploreAreaSize?: number;
}
export const Pager: React.FC<PagerProps> = ({
  buttonLabel,
  ofLabel,
  productsLabel,
  paging,
  onClick,
  morePages,
  loading = false,
}) => {
  if (!paging) {
    return null;
  }
  const { itemCount, pageNumber, pageCount, pageSize } = paging;
  if (!itemCount || pageNumber >= pageCount) {
    return null;
  }
  const currentCount =
    pageNumber * pageSize > itemCount ? itemCount : pageNumber * pageSize;
  const soFar =
    paging && typeof currentCount !== 'undefined'
      ? Math.round((currentCount / itemCount) * 100)
      : 0;
  const toGo = 100 - soFar;

  return (
    <PagerStyled>
      <div className="PagerInner">
        <div className="Pager">
          {currentCount} {ofLabel} {itemCount} {productsLabel}
        </div>
        <Gauge soFar={soFar} toGo={toGo} />

        {morePages && (
          <Button
            onClick={onClick}
            className={loading ? 'Loading Outline' : 'Outline'}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </PagerStyled>
  );
};
