export const lineHeights = {
  // 20px
  body: '1.625rem',

  h1: '3.375rem',

  // 54px
  h1mobile: '2.125rem',

  // 34px
  h2: '2.125rem',

  h2mobile: '1.75rem',

  // 28px
  h3: '1.75rem',

  h4: '1.5rem',
  // 24px
  h5: '1.25rem', // 26px
  small: '1.5rem', // 24px
  xsmall: '1.125rem', // 18px
};
