import { ArrowLink } from '../ArrowLink/ArrowLink';

import {
  StyledImage,
  StyledNoImageDiv,
  StyledSmallContentPlug,
  StyledTextBoxText,
  StyledTextBoxTitle,
} from './ContentPlug.styled';
interface ContentPlugProps {
  title?: string;
  url?: string;
  image?: {
    url?: string;
    description?: string;
  };
  text?: string;
  CTAText?: string;
  className?: string;
}
export const ContentPlug: React.FC<ContentPlugProps> = ({
  title = '',
  url = '',
  image,
  text = '',
  CTAText = '',
  className,
}) => {
  return (
    <StyledSmallContentPlug href={url} className={className}>
      {image ? (
        <StyledImage src={`${image.url}?width=800`} alt={image.description} />
      ) : (
        <StyledNoImageDiv />
      )}
      <StyledTextBoxTitle
        type="h4"
        styleType="header4"
        className="TextBoxTitle"
      >
        {title}
      </StyledTextBoxTitle>
      <StyledTextBoxText>{text ? text : '\u00A0'}</StyledTextBoxText>
      <ArrowLink
        isAnchor={false}
        label={CTAText}
        url={url}
        direction="forward"
      />
    </StyledSmallContentPlug>
  );
};
