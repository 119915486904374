import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

import { Badge } from '../Badge/Badge';

interface InFavoritesProps {
  $inFavorites?: boolean;
}
interface IsSmallProps {
  $isSmall?: boolean;
}

type ProductStyledProps = InFavoritesProps & IsSmallProps;

export const ProductLink = styled(Link)`
  text-decoration-line: none;
`;

export const ProductStyled = styled.article<ProductStyledProps>`
  position: relative;
  margin-bottom: ${({ $isSmall, $inFavorites }) =>
    $isSmall ? spacing.regular : $inFavorites ? spacing.medium : spacing.block};
`;
export const FavoriteContainer = styled.div<InFavoritesProps>`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${spacing.xsmall};
  width: ${spacing.small};
  height: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktop}) {
    inline-size: 24px;
    block-size: 24px;
  }

  pointer-events: none;
  z-index: 1;
  box-sizing: content-box;
  cursor: inherit;

  > * {
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    inline-size: ${spacing.small};
    block-size: ${spacing.small};
  }

  svg {
    inline-size: ${spacing.small};
    block-size: ${spacing.small};
  }
`;

export const ProductImage = styled.div<IsSmallProps>`
  position: relative;
  display: flex;
  background: ${colors.white};
  aspect-ratio: 1 / 1;
  border: thin solid ${colors.border};
  align-items: center;
  justify-content: center;
`;

export const ImageWrapper = styled.div<ProductStyledProps>`
  position: relative;
  transition: transform 0.3s ease-in-out;
  height: 100%;

  ${({ $isSmall }) => css`
    width: calc(100% - ${$isSmall ? spacing.small : spacing.medium});
    max-height: calc(100% - ${$isSmall ? spacing.small : spacing.medium});
  `}

  ${({ $inFavorites, $isSmall }) =>
    !$inFavorites &&
    !$isSmall &&
    css`
      ${ProductLink}:hover & {
        transform: scale(1.05);
      }
    `}
`;

export const ProductInfo = styled.div`
  padding: ${spacing.xsmall} 0 0 0;
  position: relative;
`;

export const ProductName = styled.span<InFavoritesProps>`
  display: block;
  font-size: ${({ $inFavorites }) =>
    $inFavorites ? fontSizes.xsmall : fontSizes.small};
  letter-spacing: 0.5px;
  line-height: 1.285rem;
  font-weight: ${fontWeights.normal};
  margin-bottom: ${spacing.xsmall};
  color: ${colors.black};
  transition: 0.2s ease;

  ${ProductLink}:hover & {
    color: ${colors.gray1};
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    line-height: 1.175rem;
  }
`;

export const ProductPrice = styled.span<InFavoritesProps>`
  margin: 0;
  font-size: ${({ $inFavorites }) =>
    $inFavorites ? fontSizes.xsmall : fontSizes.small};
  letter-spacing: 0.5px;
  transition: 0.2s ease;
  color: ${colors.black};

  ${ProductLink}:hover & {
    color: ${colors.gray1};
  }
`;

export const ProductBadge = styled(Badge)`
  position: relative;
`;

export const ProductBadgeWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: ${spacing.xsmall};

  &.TopLeft {
    inset-block-start: ${spacing.xsmall};
  }

  &.BottomLeft {
    inset-block-end: ${spacing.xsmall};
  }
`;
