import Image from 'next/image';

import { ProductDetailsValue } from '@hultafors/shared/types';

import {
  Pictogram,
  PictogramImage,
  PictogramsStyled,
  PictogramText,
} from './Pictograms.styled';

interface PictogramsProps {
  items?: ProductDetailsValue[];
}
export const Pictograms: React.FC<PictogramsProps> = ({ items = [] }) => {
  const itemsMapper = (item: ProductDetailsValue) => {
    return (
      <Pictogram key={`Pictogram-${item.text}`}>
        <PictogramImage>
          <Image
            src={item.image?.url || ''}
            alt={item.text}
            width={48}
            height={48}
            // layout="intrinsic"
          />
        </PictogramImage>
        <PictogramText>{item.text}</PictogramText>
      </Pictogram>
    );
  };
  return items?.length ? (
    <PictogramsStyled>{items.map(itemsMapper)}</PictogramsStyled>
  ) : null;
};
