import styled, { css, keyframes } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultafors/tokens';

const rotate = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg); opacity: 1; }
  to   { transform: translate(-50%, -50%) rotate(360deg); opacity: 1; }
`;

const appear = keyframes`
  0% { transform: translate(-50%, 20%) scale(0.3); opacity: 0; }
  85%   { transform: translate(-50%, -60%) scale(1); opacity: 1 }
  100%   { transform: translate(-50%, -50%) scale(1); opacity: 1 }
`;

interface ButtonStyledProps {
  $fullWidth?: boolean;
  $center?: boolean;
  $minWidth?: string;
  $progress?: boolean;
  $text?: boolean;
  $bold?: boolean;
  $uppercase?: boolean;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  appearance: none;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  background-color: ${colors.white};
  display: inline-block;
  height: ${spacing.medium};
  padding: 0 ${spacing.xsmall};
  box-sizing: border-box;
  border: thin solid ${colors.black};
  font-size: ${fontSizes.xsmall};
  font-weight: ${fontWeights.medium};
  color: ${colors.black};
  letter-spacing: 0.175rem;
  cursor: pointer;
  transition: 0.2s;
  text-transform: uppercase;

  svg {
    margin-inline-start: ${spacing.xsmall};
  }

  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `}

  ${({ $center }) =>
    $center &&
    css`
      display: block;
      margin: 0 auto;
    `}

  @media screen and (min-width: ${breakpoints.mediumMobile}) {
    padding: 0 ${spacing.small};
  }

  &:hover {
    background-color: ${colors.black};
    color: ${colors.white};
  }

  &.Red {
    border: thin solid ${colors.red};
    background-color: ${colors.red};
    color: ${colors.white};

    &:hover {
      border: thin solid ${colors.redHover};
      background-color: ${colors.redHover};
    }
  }

  &.Black {
    border: thin solid ${colors.black};
    background-color: ${colors.black};
    color: ${colors.white};
  }

  &.Black-hover {
    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }

  &.White {
    border: thin solid ${colors.white};
    background-color: ${colors.white};
    color: ${colors.black};
  }

  &.Inverted {
    background-color: transparent;
    border: thin solid ${colors.white};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }

  &.Filter {
    block-size: 32px;
    background-color: transparent;
    border: thin solid ${colors.border};
    border-radius: 100px;
    padding: 0 ${spacing.small};
    color: ${colors.black};
    font-size: ${fontSizes.small};
    font-weight: ${fontWeights.normal};
    text-transform: unset;
    letter-spacing: 0;
    white-space: nowrap;

    &:hover {
      background-color: ${colors.gray4};
    }

    &.Selected {
      background-color: ${colors.black};
      border-color: ${colors.black};
      color: ${colors.white};
    }
  }

  ${({ $text }) =>
    $text &&
    css`
      background-color: transparent;
      border: none;
      color: ${colors.black};
      font-weight: ${fontWeights.normal};
      text-transform: unset;
      letter-spacing: 0.04em;
      font-size: ${fontSizes.small};

      &:hover {
        background-color: transparent;
        color: ${colors.gray2};
      }

      &:focus {
        outline: 0;
      }
    `}

  ${({ $text, $bold }) =>
    $text &&
    $bold &&
    css`
      font-weight: ${fontWeights.medium};
    `}

  ${({ $text, $uppercase }) =>
    $text &&
    $uppercase &&
    css`
      text-transform: uppercase;
    `}

  &.IconButton {
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    background-color: transparent;
  }

  &.Icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    /* &:after {
      content: '';
      display: none;

      @media screen and (min-width: ${breakpoints.bigMobile}) {
        display: inline-flex;
        width: ${spacing.regular};
        height: ${spacing.regular};
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: ${spacing.xsmall};
        transition: transform 0.1s linear;
      }
    } */

    /* &.MobileFilter:after {
      content: '';
      display: inline-flex;
      width: ${spacing.small};
      height: ${spacing.small};
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: ${spacing.xsmall};
      transition: transform 0.1s linear;
    } */
  }

  &.SvgIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: ${spacing.small};

    svg {
      inline-size: 16px;
      block-size: 16px;
      margin-inline-start: 16px;
    }

    &:hover {
      color: ${colors.white};
    }
  }

  &.Loading {
    position: relative;
    font-size: 0;
    transition: all 0.3s;

    &::after {
      content: '';
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      inline-size: 24px;
      block-size: 24px;
      background-image: url('/assets/gfx/loading-circle.svg');
      background-repeat: no-repeat;
      background-position: center;
      animation: ${rotate} 1s linear;
      animation-iteration-count: infinite;
      transform-origin: center;
    }

    &:hover::after {
      background-image: url('/assets/gfx/loading-circle-white.svg');
    }
  }

  ${({ $progress }) =>
    $progress &&
    css`
      position: relative;
      transition: all 0.3s;
      overflow: hidden;
      color: ${colors.red};

      &::before {
        content: '';
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        inline-size: 24px;
        block-size: 24px;
        background-image: url('/assets/gfx/loading-circle-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        animation: ${rotate} 1s linear;
        animation-iteration-count: 1;
        transform-origin: center;
      }

      &::after {
        content: '';
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        inline-size: 32px;
        block-size: 32px;
        background-image: url('/assets/gfx/check_button.svg');
        background-repeat: no-repeat;
        background-position: center;
        transform: translate(-50%, 40%) scale(0.6);
        opacity: 0;
        animation: ${appear} 0.4s ease-out;
        animation-iteration-count: 1;
        animation-delay: 0.9s;
        animation-fill-mode: forwards;
        transform-origin: center;
      }

      &.White {
        color: ${colors.black};

        &::before {
          background-image: url('/assets/gfx/loading-circle.svg');
        }

        &::after {
          background-image: url('/assets/gfx/check_button.svg');
        }
      }

      &.HideButtonText {
        color: rgb(0 0 0 / 0%);
      }
    `};

  &:disabled {
    cursor: not-allowed;
    border: 0;
    color: ${colors.gray1};
    transition: 0.2s;
    opacity: 0.5;

    svg {
      opacity: 0.3;
    }
  }
`;
