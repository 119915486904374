import { SeoFragment } from '@hultafors/hultafors/types';

export const createMeta = (
  seo?: SeoFragment,
  lang = 'en',
  fallback: Partial<SeoFragment> = {},
) => ({
  description: seo?.description || fallback?.description || '',
  image: seo?.image || fallback?.image || null,
  lang,
  title: seo?.title || fallback?.title || '',
});

interface CreateMetaManually {
  title: string;
  description: string;
  imageUrl: string;
  lang: string;
}

export const createMetaManually = ({
  title,
  description,
  imageUrl,
  lang = 'en',
}: CreateMetaManually) => ({
  description: description ? description : '',
  image: { url: imageUrl || '' },
  lang,
  title: title ? title : '',
});
