const pageRecords = {
  articlePage: { pageName: 'articles', typename: 'ArticlePageRecord' },
  iconPage: { pageName: 'icons', typename: 'IconPageRecord' },
  newsPage: { pageName: 'news', typename: 'NewsPageRecord' },
  storyPage: { pageName: 'stories', typename: 'StoryRecord' },
};

export const getParentPage = (typename: string) => {
  switch (typename) {
    case pageRecords.storyPage.typename:
      return pageRecords.storyPage.pageName;

    case pageRecords.articlePage.typename:
      return pageRecords.articlePage.pageName;

    case pageRecords.newsPage.typename:
      return pageRecords.newsPage.pageName;

    case pageRecords.iconPage.typename:
      return pageRecords.iconPage.pageName;

    default:
      return pageRecords.articlePage.pageName;
  }
};
