import { VisibleSeoBlockFragment } from '@hultafors/hultafors/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Markdown } from '../Markdown/Markdown';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';

import { StyledParagraph } from './VisibleSeoBlockStyle';

export const VisibleSeoBlock: React.FC<VisibleSeoBlockFragment> = ({
  visibleSeoDescription,
  visibleSeoTitle,
}) => {
  return (
    <Section>
      <Grid columns={[{ columns: 1 }, { breakpoint: 'tablet', columns: 12 }]}>
        <GridChild
          columnSpan={[
            { columns: 1, start: 1 },
            { breakpoint: 'tablet', columns: 6, start: 2 },
          ]}
        >
          <StyledParagraph>{visibleSeoTitle}</StyledParagraph>
          <Markdown
            variant="dark"
            options={{
              forceBlock: true,
              overrides: { p: Paragraph },
            }}
          >
            {visibleSeoDescription}
          </Markdown>
        </GridChild>
      </Grid>
    </Section>
  );
};
