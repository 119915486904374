import { useState } from 'react';

import { FavoriteItem } from '@hultafors/shared/types';

import { FavoritesContext } from './favorites-context';

export const MockFavoritesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [favorites, setFavorites] = useState<FavoriteItem[]>([]);

  const toggleFavorite = (product: FavoriteItem) => {
    setFavorites(
      favorites?.find(({ id }) => id === product.id)
        ? favorites?.filter(({ id }) => id !== product.id) || []
        : [...(favorites || []), product],
    );
  };

  const clearFavorites = () => {
    setFavorites([]);
  };

  return (
    <FavoritesContext.Provider
      value={{
        clearFavorites,
        favorites,
        toggleFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
