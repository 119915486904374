import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import { AccordionItemOld } from '../accordion-item-old/accordion-item-old';
import { AccordionOld } from '../accordion-old/accordion-old';

interface FooterProps {
  $brand?: 'hellberg' | 'snickers' | 'solidgear' | 'hultafors' | 'wibe';
}

export const FooterStyled = styled.footer`
  inline-size: 100%;
  margin: 0;
  padding: 0;
  margin-block-start: auto;
  background-color: transparent;

  ${({ theme }) =>
    theme &&
    css`
      background-color: ${theme.colors.footer.background};
    `}
`;

export const Outer = styled.div`
  padding-inline: 16px;
  padding-block: 96px;
  max-inline-size: 1440px;
  margin-inline: auto;

  ${({ theme }) =>
    theme &&
    css`
      padding-inline: ${theme.spacing.small};
      padding-block: ${theme.spacing.medium};
    `}

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-inline: 48px;

    ${({ theme }) =>
      theme &&
      css`
        padding-inline: ${theme.spacing.medium};
      `}
  }
`;

export const Inner = styled.div`
  display: grid;
  inline-size: 100%;
  margin-inline: auto;
  gap: 50px;

  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.colors.footer.text};

      p,
      a,
      nav a {
        color: ${theme.colors.footer.text};
      }

      a:hover {
        color: ${theme.colors.footer.hover};
      }
    `}

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    grid-template-columns: 0.9fr 1.1fr;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > p {
    margin-block-end: 25px;
  }

  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.colors.footer.text};

      p:hover {
        color: ${theme.colors.footer.text};
      }
    `}
`;

export const LeftMid = styled.div<FooterProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p,
  a {
    margin-block: 5px;

    ${({ $brand }) =>
      $brand === 'solidgear' &&
      css`
        margin-block: 0;
      `}
  }

  > * {
    max-inline-size: 460px;
  }

  ${({ theme }) =>
    theme &&
    css`
      a:hover {
        color: ${theme.colors.footer.hover};
      }
    `}

  & >:hover {
    color: inherit;
  }
`;

export const LogoLink = styled(Link)`
  min-block-size: 16px;
  min-inline-size: 16px;
  display: block;
  margin-block-end: 20px;

  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.colors.logoColor};
      max-block-size: ${theme.spacing.medium};
      max-inline-size: ${theme.spacing.medium};
    `}
`;

export const FooterIntro = styled.span<FooterProps>`
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  max-inline-size: 460px;

  ${({ $brand, theme }) =>
    theme &&
    $brand &&
    $brand === 'snickers' &&
    css`
      font-weight: ${theme.fonts.weights.regular};
    `}
`;

export const BottomSectionLinks = styled.nav<FooterProps>`
  display: flex;
  flex-wrap: wrap;
  max-inline-size: 500px;

  > * {
    position: relative;
    padding-block: 2px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-inline-end: 16px;
    }

    ${({ theme }) =>
      theme &&
      css`
        &:not(:last-child) {
          margin-inline-end: ${theme.spacing.small};
        }
      `}
  }

  > *:not(:last-child)::after {
    content: '|';
    position: absolute;
    inset-inline-end: -10px;
  }

  a {
    text-decoration: none;
    color: #000;
    margin-block: 2px;

    ${({ theme }) =>
      theme &&
      css`
        color: ${theme.colors.black.regular};
      `}

    ${({ $brand }) =>
      $brand === 'solidgear' &&
      css`
        margin-block: 0;
      `}
  }
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
  color: #000;
  margin-block: 8px;
  position: relative;

  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.colors.black.regular};
      margin-block: ${theme.spacing.xsmall};
    `}
`;

export const RightSection = styled.div`
  display: grid;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    gap: 50px;
  }

  @media screen and (width >= 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const MobileMenu = styled(AccordionOld).attrs({
  allowZeroExpanded: true,
})`
  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: none;
  }
`;

export const MobileMenuItem = styled(AccordionItemOld)<FooterProps>`
  border-block-end: 1px solid black;

  span {
    font-weight: 500;
    padding-block-end: 8px;
  }

  div:has(a) {
    display: flex;
    flex-direction: column;
  }

  svg {
    inline-size: 20px;
    block-size: 20px;
  }

  ${({ theme }) =>
    theme &&
    css`
      border-block-end: ${theme.colors.footer.text};

      svg g {
        stroke: ${theme.colors.footer.text};
      }

      span {
        font-weight: ${theme.fonts.weights.bold};
        color: ${theme.colors.footer.text};
      }
    `}

  ${({ $brand, theme }) =>
    theme &&
    $brand &&
    $brand === 'snickers' &&
    css`
      span {
        font-weight: ${theme.fonts.weights.regular};
      }
    `}


  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    border-block-end: none;
  }
`;

export const DesktopMenu = styled.div`
  inline-size: 100%;
  display: none;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: flex;
    flex-direction: column;
  }
`;

export const DesktopMenuItem = styled.div`
  display: flex;
  flex-direction: column;

  :last-child {
    align-items: flex-end;
  }
`;

export const DesktopMenuTitle = styled.span<FooterProps>`
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;

  ${({ theme }) =>
    theme &&
    css`
      font-weight: ${theme.fonts.weights.bold};
    `}

  ${({ $brand, theme }) =>
    theme &&
    $brand &&
    $brand === 'snickers' &&
    css`
      font-weight: ${theme.fonts.weights.regular};
    `}
`;

export const SocialMediaSection = styled.div<FooterProps>`
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;

  > span {
    display: block;
    margin-block-end: 10px;

    ${({ $brand, theme }) =>
      theme &&
      $brand &&
      $brand === 'snickers' &&
      css`
        font-weight: ${theme.fonts.weights.regular};
      `}
  }
`;

export const SocialIcon = styled.img`
  inline-size: 100%;
  block-size: 100%;

  &:not(:last-of-type) {
    margin-inline-end: 16px;
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  padding-block-end: 48px;

  ${({ theme }) =>
    theme &&
    css`
      padding-block-end: ${theme.spacing.medium};

      a {
        position: relative;
        inline-size: 32px;
        block-size: 32px;

        &:not(:last-of-type) {
          margin-inline-end: ${theme.spacing.small};
        }
      }
    `}

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block-end: 0;
  }
`;

export const FooterEndContainer = styled.div`
  inline-size: 100%;
  margin-block-start: 50px;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-block-start: 0;
  }
`;

export const MarketPickerToggler = styled.button`
  border: none;
  border-radius: 3px;
  background-color: white;
  inline-size: 100%;
  margin-block-start: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 25px;
  padding-block: 5px;
  cursor: pointer;
  transition: filter 100ms ease-in-out;

  &:hover {
    filter: drop-shadow(0 2px 5px rgb(0 0 0 / 10%));
  }
`;

export const TogglerTextContainer = styled.div<FooterProps>`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  align-items: flex-start;
  color: #000;

  span:last-child {
    font-size: 12px;
    color: #b2b2b2;

    ${({ theme, $brand }) =>
      theme &&
      $brand &&
      $brand === 'snickers' &&
      css`
        font-weight: ${theme.fonts.weights.regular};
      `}
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-inline-start: 5px solid transparent;
  border-inline-end: 5px solid transparent;
  border-block-start: 5px solid black;
`;

export const CookieSettings = styled.button`
  ${buttonReset};
  cursor: pointer;
  color: #000;
  margin-block: 8px;

  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.colors.footer.text};
      margin-block: ${theme.spacing.xsmall};
    `}
`;
