import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, spacing } from '@hultafors/hultafors/tokens';

interface CollapseStyledProps {
  $noPadding?: boolean;
  $small?: boolean;
  $height?: number;
}

export const CollapseStyled = styled.div<CollapseStyledProps>`
  height: auto;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 0;
    padding: 0;
  }

  .CollapseHeader {
    position: relative;
    display: flex;
    align-items: center;
    block-size: ${spacing.large};
    margin: 0;

    .InnerCollapse {
      display: flex;
      flex-direction: column;

      h5 {
        inline-size: 100%;
      }

      .SubLabel {
        font-size: ${fontSizes.xsmall};
        color: ${colors.gray1};
        inline-size: 100%;
        display: inline-block;
        margin-block-start: ${spacing.xxsmall};
      }
    }

    &::after {
      content: '';
      position: absolute;
      display: inline-flex;
      inset-inline-end: 0;
      inset-block-start: 50%;
      inline-size: 12px;
      block-size: 12px;
      background: url('/assets/gfx/chevron.svg') no-repeat center center;
      transform: translateY(-50%);
      transition: transform 0.3s;
    }

    &.isOpen::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .CollapseContent {
    block-size: auto;
    max-block-size: 0;
    transition: max-height 0.4s;
    overflow-y: hidden;
    padding-inline-start: ${({ $noPadding }) =>
      $noPadding ? '0' : spacing.regular};

    &.isOpen {
      max-block-size: ${({ $height }) => $height}px;
    }

    span {
      display: flex;
      inline-size: 100%;

      a {
        margin: ${spacing.xsmall} 0;
        font-size: ${fontSizes.small};
      }
    }
  }
`;
