import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultafors/tokens';

import { Markdown } from '../Markdown/Markdown';

interface TextProps {
  $alignTextCenter?: boolean;
  $alignTextTop?: boolean;
  $hasImage?: boolean;
}

export const PersonellListStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-inline: auto;
  width: 100%;
  max-width: 1440px;
  padding-inline: 20px;
  margin-block: ${spacing.large};

  @media screen and (width >= ${breakpoints.largeMobile}) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media screen and (width >= ${breakpoints.tablet}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  grid-column: span 5;
  place-self: stretch;

  @media screen and (width >= ${breakpoints.tablet}) {
    grid-column: 2 / span 10;
  }

  @media screen and (width >= ${breakpoints.desktop}) {
    grid-column: 3 / span 6;
  }

  @media screen and (width >= ${breakpoints.desktopMedium}) {
    grid-column: 4 / span 6;
  }
`;

export const Person = styled.div`
  display: grid;
  column-gap: 20px;
  place-items: stretch stretch;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-column: span 4;

  @media screen and (width >= ${breakpoints.largeMobile}) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  @media screen and (width >= ${breakpoints.tablet}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
`;

export const ImageWrapper = styled.div`
  aspect-ratio: 1;
  position: relative;
  width: 100%;
  grid-column: span 4;

  @media screen and (width >= ${breakpoints.largeMobile}) {
    grid-column: span 2;
  }

  @media screen and (width >= ${breakpoints.tablet}) {
    grid-column: 1 / span 3;
  }
`;

export const TextWrapper = styled.div<TextProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-column: span 4;

  ${({ $alignTextCenter }) =>
    $alignTextCenter &&
    css`
      justify-content: center;
    `}

  ${({ $alignTextTop }) =>
    $alignTextTop &&
    css`
      justify-content: flex-start;
    `}

  @media screen and (width >= ${breakpoints.largeMobile}) {
    grid-column: 3 / span 4;
  }

  @media screen and (width >= ${breakpoints.tablet}) {
    grid-column: 4 / span 5;
  }

  ${({ $hasImage }) =>
    !$hasImage &&
    css`
      justify-content: center;

      @media screen and (width >= ${breakpoints.largeMobile}) {
        grid-column: span 2;
      }

      @media screen and (width >= ${breakpoints.tablet}) {
        grid-column: 1 / span 3;
      }
    `}
`;

export const InfoText = styled(Markdown)<TextProps>`
  font-size: 0.875rem;
  line-height: 24px;
  white-space: pre-wrap;

  @media screen and (width >= ${breakpoints.largeMobile}) {
    ${({ $alignTextCenter }) =>
      $alignTextCenter &&
      css`
        margin-block: 0;
      `}

    ${({ $alignTextTop }) =>
      $alignTextTop &&
      css`
        margin-block: 0;
      `}
  }

  @media screen and (width >= ${breakpoints.desktop}) {
    font-size: 1rem;
    line-height: 26px;
  }
`;
