import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultafors/tokens';
import { ImageFragment } from '@hultafors/hultafors/types';

interface HeroBlockStyledProps {
  $image?: ImageFragment | null;
  $darkText?: boolean;
  $small?: boolean;
  $video?: boolean;
}

function containerMaxHeight({ $image, $video, $small }: HeroBlockStyledProps) {
  if (($image || $video) && $small) {
    return '800px';
  }

  if ($image || $video) {
    return null;
  }

  return '375px';
}

export const HeroBlockStyled = styled.div<HeroBlockStyledProps>`
  overflow: hidden;
  position: relative;
  height: auto;
  width: 100%;
  max-height: ${containerMaxHeight};
  color: ${({ $image, $video, $darkText }) =>
    ($image || $video) && !$darkText ? colors.white : colors.black};
  margin-top: ${({ $image, $video }) => ($image || $video ? '0' : '57px')};

  /* TODO When fetch one image is fixed, use image aspect ratio instead */
  aspect-ratio: 3 / 4;
  border-bottom: ${({ $image, $video }) =>
    $image || $video ? 'none' : 'thin solid ' + colors.gray4};
  z-index: 1;

  &.NoBorderBottom {
    border-block-end: none;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 16 / 9;
  }

  ${({ $image, $video, $darkText }) =>
    ($image || $video) &&
    !$darkText &&
    css`
      &::before {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        inset-block-end: 0;
        inline-size: 100%;
        block-size: 100%;
        background: linear-gradient(
          114deg,
          rgb(0 0 0 / 71%) 6%,
          rgb(0 0 0 / 5%) 95%
        );
        z-index: 1;
      }
    `}
  .LeftContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 9.5rem 30px 6rem;
  }

  &.Small {
    block-size: 800px;
  }

  .Inner {
    max-inline-size: 700px;
    margin-inline-start: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.desktop}) {
      margin-inline-start: calc(10 * 1.2vw);
    }

    .HeroLabel {
      display: block;
      margin-block-end: ${spacing.small};
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-weight: ${fontWeights.medium};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 4px;
        margin-block-end: ${spacing.regular};
      }
    }

    > h2 {
      max-inline-size: 700px;
      font-size: ${fontSizes.h1mobile};
      line-height: ${lineHeights.h1mobile};
      font-weight: ${fontWeights.normal};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.h1};
        line-height: ${lineHeights.h1};
      }
    }

    .HeroDescription {
      display: 'block';
      margin-block-start: ${spacing.small};
      max-inline-size: 450px;

      p {
        font-size: ${fontSizes.h5};
        line-height: ${lineHeights.h4};
        font-weight: ${fontWeights.normal};
      }

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        display: block;
        margin-block-start: ${spacing.medium};
        font-size: ${fontSizes.h4};
      }

      a {
        color: inherit;
      }
    }
  }

  .HeroMobileDescription {
    display: 'none';

    @media screen and (max-width: ${breakpoints.mobileMax}) {
      display: none;
      inline-size: 100%;
      block-size: auto;
      padding: ${spacing.medium} ${spacing.pagePaddingMobile};
      background: ${({ $darkText }) =>
        $darkText ? colors.gray5 : colors.black};
      color: ${({ $darkText }) => ($darkText ? colors.black : colors.white)};
      box-sizing: border-box;
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      display: none;
    }
  }
`;

export const HeroScrollText = styled.div<{
  $image: boolean;
  $video?: boolean;
  $darkText?: boolean;
}>`
  display: none;
  z-index: 2;

  @media screen and (min-width: ${breakpoints.desktop}) {
    position: absolute;
    display: ${({ $image, $video }) => ($image || $video ? 'flex' : 'none')};
    block-size: ${spacing.regular};
    inset-inline-start: ${spacing.medium};
    inset-block-end: ${spacing.xsmall};
    transform: rotate(-90deg);
    transform-origin: bottom left;
    align-items: center;

    svg {
      inline-size: 16px;
      block-size: 16px;
      transform: rotate(90deg);

      g {
        fill: ${({ $darkText }) => ($darkText ? colors.black : colors.white)};
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      letter-spacing: 1px;
      margin-inline-start: ${spacing.xsmall};

      &::before {
        content: '';
        display: inline-flex;
        block-size: 1px;
        inline-size: 72px;
        margin-inline-end: ${spacing.xsmall};
        background: ${({ $darkText }) =>
          $darkText ? colors.black : colors.white};
      }
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    inset-block-end: ${spacing.small};
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    inset-block-end: ${spacing.xlarge};
  }
`;

export const HeroButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    margin-block: ${spacing.pagePaddingMobile};
  }

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    > :last-child {
      margin-block-end: 0;
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;

    > :first-child {
      margin-inline-end: ${spacing.pagePaddingMobile};
    }

    > :last-child {
      margin-block-start: ${spacing.medium};
    }
  }
`;

export const HeroButtonWrapper = styled.div<{ $dark?: boolean }>`
  margin-top: ${spacing.block};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: ${spacing.medium};
  }
`;

export const VideoStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
    aspect-ratio: 2 / 3;
    block-size: 100%;
    inline-size: 100%;

    @media screen and (min-width: ${breakpoints.desktop}) {
      aspect-ratio: 16 / 9;
    }
  }
`;

export const HeroContent = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 2;
`;

export const IconContainer = styled.div`
  margin-top: auto;
  margin-left: auto;
  position: absolute;
  aspect-ratio: 1 / 1;
  bottom: 0;
  right: 0;
  width: 40%;

  @media screen and (min-width: ${breakpoints.desktop}) {
    inline-size: 30%;
  }
`;
