export const fontSizes = {
  // 18px
  body: '1rem',

  h1: '3.125rem',

  h1mobile: '1.875rem',

  h2: '1.875rem',

  h2mobile: '1.5rem',

  h3: '1.5rem',

  h4: '1.25rem',
  // 20px
  h5: '1.125rem',
  small: '0.875rem',
  supersmall: '0.625rem',
  // 14px
  xsmall: '0.75rem',
};
