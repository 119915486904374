import { useMemo } from 'react';

import { FavoriteItem } from '@hultafors/shared/types';

import { useFavorites, useGlobal } from '@hultafors/hultafors/hooks';

import { Icon } from '../Icon/Icon';

import { FavoriteStyled } from './FavoriteToggle.styled';

interface FavoriteToggleProps {
  product: FavoriteItem;
  small?: boolean;
}
export const FavoriteToggle: React.FC<FavoriteToggleProps> = ({
  product,
  small,
}) => {
  const { globalContent } = useGlobal();

  const { favorites = [], toggleFavorite } = useFavorites();
  const isFavorite: boolean = useMemo(() => {
    return !!favorites.find(({ id }) => id === product.id);
  }, [favorites, product]);
  const ariaLabel: string | undefined = useMemo(() => {
    if (
      !globalContent.removeFromFavoritesLabel ||
      !globalContent.addToFavoritesLabel
    ) {
      return '';
    }

    if (isFavorite) {
      return globalContent.removeFromFavoritesLabel;
    }
    return globalContent.addToFavoritesLabel;
  }, [isFavorite, globalContent]);
  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    toggleFavorite(product);
  };
  return (
    <FavoriteStyled
      $isFavorite={isFavorite}
      onClick={onClick}
      $small={small}
      aria-label={ariaLabel}
      data-test-id="FavoriteToggle"
    >
      <Icon svg="favorite" />
    </FavoriteStyled>
  );
};
