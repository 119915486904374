import { createImageUrl } from '@hultafors/hultafors/helpers/utils';

import { ImageStyled } from './Image.styled';

interface ImageProps {
  src?: string;
  caption?: string;
  alt?: string;
  sizes?: string;
  responsive?: boolean;
  width?: string;
}

export const Image: React.FC<ImageProps> = ({
  src,
  caption,
  alt,
  sizes,
  responsive,
  width,
}) => {
  const setupImageMetadata = () => {
    const defaultCaption = 'Image caption';

    let imageCaption = '';
    if (
      !caption ||
      caption === '' ||
      caption === undefined ||
      caption === null
    ) {
      imageCaption = defaultCaption;
    } else {
      imageCaption = caption;
    }
    return imageCaption;
  };

  const imgCaption = setupImageMetadata();

  if (responsive) {
    return (
      <ImageStyled>
        <img
          src="/assets/img/image.jpg"
          srcSet="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          data-srcSet={`
            ${createImageUrl(src, 'jpg', 768, 30)} 768w,
            ${createImageUrl(src, 'jpg', 2880, 30)} 2880w,
            ${createImageUrl(src, 'jpg', 1920, 30)} 1920w,
            ${createImageUrl(src, 'jpg', 1440, 30)} 1440w,
            ${createImageUrl(src, 'jpg', 1024, 30)} 1024w,
            ${createImageUrl(src, 'jpg', 480, 30)} 480w,
            ${createImageUrl(src, 'jpg', 320, 30)} 320w`}
          data-src={createImageUrl(src, 'jpg', 768, 30)}
          data-sizes="auto"
          alt={imgCaption}
          title={imgCaption}
          style={{ maxWidth: '100%', width }}
          className="lazyload"
        />
      </ImageStyled>
    );
  } else if (!responsive) {
    return (
      <img
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        data-src={src}
        alt={imgCaption}
        title={imgCaption}
        style={{ maxWidth: '100%', width }}
        className="lazyload"
      />
    );
  }
  return null;
};
